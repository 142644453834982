
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { isMobile } from "react-device-detect";
import { setMainData } from 'actions';
import Frame from './frame';
import WebWdg from 'webwidget';
import { ChromePicker } from 'react-color'
import { sendApiKeyRequest } from 'components/base';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { googlecode, atomOneDarkReasonable } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Clipboard from 'react-clipboard.js';


const mapStateToProps = (state) => ({
  bgColor: state.main.bgColor,
  fgColor: state.main.fgColor
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setState: data => dispatch(setMainData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { fgColor, bgColor } = props

  const [ site, setSite ] = useState('home');
  const [ key, setKey ] = useState('');

  const onSite = useCallback(s => setSite(s), [site]);

  const onGetKey = useCallback(k => setKey(k));

  return (
    <Frame name='developers'>
      <h1 className={'special-dock-title' + (isMobile ? ' special-dock-title-mob' : '')} style={{ color: fgColor, flex: 1 }}>Wstaw Biblię na co dzień na swoją stronę!</h1>
      {site === 'conf' ? <Configurator onSite={onSite} webkey={key}/> : null}
      {site === 'home' ? <Home onSite={onSite}/> : null}
      {site === 'key' ? <GetKey onSite={onSite} getKey={onGetKey} /> : null}
      {site === 'api' ? <GetApi onSite={onSite} webkey={key} /> : null}
      <div style={{ flex: 1 }}/>
    </Frame>
  )
})


const Home = connect(mapStateToProps, mapDispatchToProps)(props => {
  const { fgColor, bgColor, onSite } = props

  const [ showCode, setShowCode ] = useState(false);

  const route = useCallback(name => () => onSite(name), [onSite])

  return (
    <div
      className={'special-dock-content' + (isMobile ? ' special-dock-content-mob' : '')}
      style={{ borderColor: fgColor, backgroundColor: bgColor, color: fgColor }}
    >
      <p
        className={'special-dock-label' + (isMobile ? ' special-dock-label-mob' : '')}
      >Wybierz czynność</p>
      <p className={'special-dock-addlabel' + (isMobile ? ' special-dock-addlabel-mob' : '')}>
        Polecamy wstawić Biblię na co dzień na swoją stronę. Możesz to zrobić na dwa sposoby:<br/>
        1. Udostępniamy skrypt javascript, który nadpisuje odpowiednio skonfigurowany <code>{'<div>'}</code> na Twojej stronie.<br/>
        2. Udostępniamy otwarte restowe API, dzięki któremu otrzymasz dane w formacie JSON.<br/>
        Aby skomunikaować się z serwerem niezbędne jest wygenerowanie klucza.
        Na potrzeby testów udostępniamy klucz dedykowany dla domeny <code>http://localhost</code> 
        <code onClick={()=>setShowCode(true)} style={{ cursor: showCode ? 'text' : 'pointer' }}>{showCode ? '3j98117XFhTP4Ca2Dj12urMWF7hi9GM5' : 'kliknij aby pokazać'}</code><br/>
        W razie komplikacji lub problemów z obsługą, prosimy o kontakt: <a href='mailto:ksiazki@augustana.pl'>ksiazki@augustana.pl</a>.
      </p>
      <div className="special-dock-mainbuttons-container">
        <div
          className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
          onClick={route('key')}
        >
          pozyskaj klucz api
        </div>
        <div
          className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
          onClick={route('conf')}
        >
          konfigurator widgetu
        </div>
        <div
          className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
          onClick={route('api')}
        >
          serwis API
        </div>
      </div>
    </div>
  )
})


const Configurator = connect(mapStateToProps, mapDispatchToProps)(props => {
  const { fgColor, bgColor, onSite, webkey } = props

  const [att, setAtt] = useState({
    withImage: false,
    withFilter: false,
    filterColor: '#273483c0',
    fontColor: fgColor,
    bannerColor: fgColor,
    gradient: false,
    mono: false,
    zoom: 1,
  })

  const [showFilterPicker, setShowFilterPicker] = useState(false);
  const [showFontPicker, setShowFontPicker] = useState(false);
  const [showBannerPicker, setShowBannerPicker] = useState(false);

  const updateBoolAtt = useCallback(name => eve => {
    setAtt({ ...att, [name]: eve.target.checked });
  }, [att])

  const colorChange = useCallback(name => eve => {
    setAtt({ ...att, [name]: eve.hex});
  }, [att])

  const setZoom = useCallback( eve => setAtt({ ...att, zoom: eve.target.value}), [att]);
  const onContext = useCallback(eve => {
    eve.preventDefault();
    return false;
  }, [])
  const goBack = useCallback(() => onSite('home'), [onSite]);

  return (
    <div
      className={'special-dock-content special-dock-content-max' + (isMobile ? ' special-dock-content-mob' : '')}
      style={{ borderColor: fgColor, backgroundColor: bgColor, color: fgColor }}
    >
      <div className="special-dock-title-container">
        <MdChevronLeft className="special-dock-close" style={{ position: 'relative', margin: 0 }} color={fgColor} size={40} onClick={goBack}/>
        <p className='special-dock-label'>
          Konfiguracja widgetu
        </p>
        <div/>
      </div>
      <div className='special-dock-buttons-container'>
        <div className='special-dock-buttons-subcontainer'>
          <label className="form-switch special-dock-btt-label"><input type="checkbox" value={att.withImage} onChange={updateBoolAtt('withImage')} /><i/> Zdjęcie w tle</label>
          <label className="form-switch special-dock-btt-label"><input type="checkbox" value={att.withFilter} onChange={updateBoolAtt('withFilter')} /><i/> Filtr tła</label>
          <label className="form-switch special-dock-btt-label"><input type="checkbox" value={att.gradient} onChange={updateBoolAtt('gradient')} /><i/> Gradient tła</label>
          <label className="form-switch special-dock-btt-label"><input type="checkbox" value={att.mono} onChange={updateBoolAtt('mono')} /><i/> Tryb mono baneru</label>
        </div>
        <div className='special-dock-buttons-subcontainer'>
          <label className='special-dock-btt-label' onundefinedClick={() => setShowFilterPicker(true)}><div className='special-dock-colorsquare' style={{ backgroundColor: att.filterColor || '#273483c0', borderColor: fgColor }}/> Kolor filtra</label>
          <label className='special-dock-btt-label' onClick={() => setShowFontPicker(true)}><div className='special-dock-colorsquare' style={{ backgroundColor: att.fontColor || '#fff', borderColor: fgColor }}/> Kolor fontu</label>
          <label className='special-dock-btt-label' onClick={() => setShowBannerPicker(true)}><div className='special-dock-colorsquare' style={{ backgroundColor: att.bannerColor || '#ddd', borderColor: fgColor }}/> Kolor banera mono</label>
          <label className='special-dock-btt-label'>Skaluj font<input onContextMenu={onContext} type='range' className="slider" style={{ maxWidth: 200, width: '40%' }} value={att.zoom} onChange={setZoom} min={0.2} max={2} step={0.01} /></label>
        </div>
      </div>
      <div style={{width: '75%', margin: '50px auto'}}>
        <WebWdg
          webkey="3H6q8ME5jcGum55TDj12urMWHbLq44D1"
          withImage={att.withImage}
          withFilter={att.withFilter}
          filterColor={att.filterColor}
          fontColor={att.fontColor}
          bannerColor={att.bannerColor}
          gradient={att.gradient}
          mono={att.mono}
          zoom={att.zoom}
        />
      </div>
      <p className='special-dock-addlabel'>
        Wklej w wybranym miejscu w kodzie html twojej strony:
      </p>
      <Clipboarder>
        {`<div id="BNCD-webwidget" webkey="${webkey ? webkey : '/twój klucz/'}" ${att.withImage ? 'withImage' : ''} ${att.withFilter ? 'withFilter' : ''} ${att.gradient ? 'gradient' : ''} ${att.mono ? 'mono' : ''} zoom="${att.zoom}" filterColor="${att.filterColor}" fontColor="${att.fontColor}" bannerColor="${att.bannerColor}"></div>`}
      </Clipboarder>
      <p className='special-dock-addlabel' style={{ marginTop: 50 }}>
        Wklej na samym końcu strony tuż przed <code>{'</body>'}</code>:
      </p>
      <Clipboarder>
        {'<script src="https://bncd-storage.s3.eu-north-1.amazonaws.com/media/js/webwidget.min.js"></script>'}
      </Clipboarder>
      {showFilterPicker ? <div className="special-dock-colorpicker" style={{ backgroundColor: bgColor, borderColor: fgColor }}>
        <ChromePicker color={att.filterColor || '#273483c0'} onChangeComplete={colorChange('filterColor')}/>
        <div
          className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
          onClick={() => setShowFilterPicker(false)}
        >
          <p>ok</p>
        </div>
      </div> : null}
      {showFontPicker ? <div className="special-dock-colorpicker" style={{ backgroundColor: bgColor, borderColor: fgColor }}>
        <ChromePicker color={att.fontColor || '#fff'} onChangeComplete={colorChange('fontColor')}/>
        <div
          className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
          onClick={() => setShowFontPicker(false)}
        >
          <p>ok</p>
        </div>
      </div> : null}
      {showBannerPicker ? <div className="special-dock-colorpicker" style={{ backgroundColor: bgColor, borderColor: fgColor }}>
        <ChromePicker color={att.bannerColor || '#ddd'} onChangeComplete={colorChange('bannerColor')}/>
        <div className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')} onClick={() => setShowBannerPicker(false)}>
          <p>ok</p>
        </div>
      </div> : null}
    </div>
  )
})

const GetKey = connect(mapStateToProps, mapDispatchToProps)(props => {
  const { fgColor, bgColor, onSite, getKey } = props

  const [ domain, setDomain ] = useState('');
  const [ valid, setValid ] = useState(false);
  const [ key , setKey ] = useState('')
  const [ error, setError ] = useState('');

  const goBack = useCallback(() => onSite('home'), [onSite]);
  const goAhead = useCallback(() => onSite('conf'), [onSite]);

  const onDomain = useCallback(eve => {
    const v = eve.target.value;
    setError('');
    setDomain(v);
    const lst = v.split('/');
    console.log('lst', lst);
    if (lst.length >= 3) {
      console.log('lst[2]', lst[2]);
      if (lst[0] === 'http:' || lst[0] === 'https:') {
        console.log('http ok')
        const [b0, b1] = lst[2].split('.')
        console.log('b', b0, b1)
        if (b0 && b1 && b1.length >= 2) {
          console.log('b1 ok so OK')
          setValid(true)
          return;
        };
      }
    }
    setError('Podany adres ma złą składnię.');
    setValid(false);
  }, [domain, valid]);

  const onSend = useCallback(() => {
    if (valid) {
      setValid(false);
      sendApiKeyRequest(
        domain,
        (data) => {
          setKey(data.key ? data.key : '')
          setValid(true);
          setError(data.info);
          getKey(data.key)
        },
        message => {
          switch (message) {
            case 'no connection':
              setError('Nie da się połączyć z podaną domeną, sprawdź czy adres twojej strony internetowej jest poprawny.')
              break;
            case 'bad schema':
              setError('Podany adres ma złą składnię.')
              break;
            case 'inner error':
              setError('Wystąpił problem logiczny z połączeniem. Proszę spróbować jeszcze raz.')
              break;
            case 'bad validation':
              setError('Problem wewnętrzny. Proszę skontaktować się z administratorem serwisu.')
              break;
            default:
              setError('Wystąpił niespodziewany problem. Proszę skontaktować się z administratorem serwisu.')
              break;
          }
          setValid(true);
        }
      );
    }
  }, [domain, valid, key, error, getKey])

  const onKey = useCallback(eve => {
    if (eve.key === 'Enter') {
      console.log('enter');
      onSend();
    }
  }, [onSend]);

  return (
    <div
      className={'special-dock-content special-dock-content-max' + (isMobile ? ' special-dock-content-mob' : '')}
      style={{ borderColor: fgColor, backgroundColor: bgColor, color: fgColor }}
    >
      <div className="special-dock-title-container">
        <MdChevronLeft className="special-dock-close" style={{ position: 'relative', margin: 0 }} color={fgColor} size={40} onClick={goBack}/>
        <p
          className={'special-dock-label' + (isMobile ? ' special-dock-label-mob' : '')}
        >Generator klucza API</p>
        {key ? <MdChevronRight className="special-dock-close" style={{ position: 'relative', margin: 0 }} color={fgColor} size={40} onClick={goAhead}/> : <div/>}
      </div>
      <p className={'special-dock-addlabel' + (isMobile ? ' special-dock-addlabel-mob' : '')}
      >Podaj adres strony, na której chcesz umieścić widget lub dokonać integracji z restowym API. Adres musi być pełny to znaczy, że ma się zaczynać od <code>{'http'}</code> lub <code>{'https'}</code>. Klucz jest niezbędny do wymiany danych i unikalny dla każdego klienta/strony. Serwis podczas odpytywania porównuje host w zapytaniu ze zdeklarowaną nazwą strony. Nie musisz podawać dokładnej podstrony, na której widget będzie wyświetlany. Wystarczy główny adres strony. Np: <code>{'https://luteranie.pl'}</code></p>
      <input
        className={'special-dock-input' + (isMobile ? ' special-dock-input-mob' : '')}
        placeholder="https://www.mojastrona.com"
        onChange={onDomain}
        onKeyDown={onKey}
      />
      <div
        style={{ transform: `scale(${valid ? 1 : 0})` }}
        className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
        onClick={onSend}
      >
        Wyślij żądanie klucza
      </div>
      {key ? <Clipboarder>{key}</Clipboarder> : null}
      <p className={'special-dock-error' + (isMobile ? ' special-dock-error-mob' : '')}>{error}</p>
    </div>
  )
})


const GetApi = connect(mapStateToProps, mapDispatchToProps)(props => {
  const { fgColor, bgColor, onSite } = props

  const goBack = useCallback(() => onSite('home'), [onSite]);

  return (
    <div
      className={'special-dock-content special-dock-content-max' + (isMobile ? ' special-dock-content-mob' : '')}
      style={{ borderColor: fgColor, backgroundColor: bgColor, color: fgColor }}
    >
      <div className="special-dock-title-container">
        <MdChevronLeft className="special-dock-close" style={{ position: 'relative', margin: 0 }} color={fgColor} size={40} onClick={goBack}/>
        <p className='special-dock-label'>API</p>
        <div/>
      </div>
      <p className='special-dock-api'>
      Możesz odpytać serwis Biblia na co dzień o dzisiejsze Słowo w formacie json, przy pomocy protokołu http, zapytania typu POST.
      Każdorazowo wymagany jest klucz api w ciele zapytania.
      Ponadto wymagane jest, aby zapytanie odbywało się od hosta, który został podany jako domena, przy żądaniu klucza.
      Endpoint dostępny jest pod adresem:
      </p>
      <Clipboarder>https://db.bncd.stream/bncd/api/open-node/</Clipboarder>
      <p className='special-dock-api'>
      W odpowiedzi, jedna z wartości to <br/>"status": (str) 'fail' || 'ok'<br/>
      Mówi ona czy dane zostały pobrane poprawnie.
      W przypadku statusu 'fail', towarzyszyć mu będzie wartość <br/>"error": (str) 'no data' || 'no key' || 'bad key' || 'bad domain' || 'no domain'<br/><br/>
      'no data' - brak danych na serwerze, możliwy błąd wewnętrzny serwisu. Prosimy o kontakt z administratorem serwisu.<br/>
      'no key' - nie podano klucza.<br/>
      'bad key' - błędny klucz.<br/>
      'no domain' - żądanie jest tak skonstruowane, że niemożliwe jest odczytanie hosta.<br/>
      'bad domain' - niezgodny host z domeną podaną przy żądaniu klucza.<br/><br/>
      W przypadku statusu 'fail', serwis zwraca kod odpowiedzi 200.
      W przypadku tekstów pierwszego, drugiego i trzeciego, są one zwracane w postaci stringa z kodem html.
      Wstępne stylowanie składni znacznikami html, jest narzucone ze względu na zachowanie poprawności zapisu ustalonego przez wydawcę.
      Oto przykładowe zapytanie przy pomocy narzędzia fetch w javascript:
      </p>
      <div className="special-codebox">
        <SyntaxHighlighter language="javascript" style={bgColor === '#000' ? atomOneDarkReasonable : googlecode}>
        {`fetch('https://db.bncd.stream/bncd/api/open-node/', {
  method: 'POST',
  body: JSON.stringify({ key: "/^klucz api^/" })
})
.then((response) => response.json())
.then((data) => {
  if (data.status === 'ok') {
    /^zrób coś z danymi^/
  } else {
    /^obsłuż wyjątek kontrolowany^/
  }
})
.catch(error => {
  /^obsłuż wyjątek niekontrolowany^/
});`}
        </SyntaxHighlighter>
      </div>
      <p className='special-dock-api'>
W myśl zasady, że jeden przykład jest wart więcej niż tysiąc słów, poniżej przykład pełnych odpowiedzi dla niedzieli (dnia świątecznego) i dnia powszedniego:
      </p>
      <div className="special-codebox">
        <SyntaxHighlighter language="json" style={bgColor === '#000' ? atomOneDarkReasonable : googlecode}>
        {`{
  "status": "ok",
  "date": "22.03.2020",
  "day": "NIEDZIELA",
  "is_name": true,
  "name": "4. Pasyjna (Laetare – Radujcie się z Jeruzalemem... Iz 66,10)",
  "is_first": true,
  "first": "<p><b>Jest jednak Bóg na niebie, który objawia tajemnice.</b></p>",
  "first_s": "Dn 2,28",
  "is_second": true,
  "second": "<p>W Chrystusie<b> są ukryte wszystkie skarby mądrości i poznania.</b></p>",
  "second_s": "Kol 2,3",
  "is_third": true,
  "third": "<p>Panie Jezu, niech Twoje błogosławieństwo wypełni wszystkich, którzy Cię kochają! Ci, którzy przychodzą do Ciebie, niech będą zapisani w niebie, niech ukryci w cieniu Twoich skrzydeł zawsze żyją w pokoju.</p>",
  "third_s": "Anzelm z Canterbury †1109",
  "image": "https://bncd-storage.s3.amazonaws.com/media/images/biblia3.jpg"
}`}
        </SyntaxHighlighter>
      </div>
      <div className="special-codebox">
        <SyntaxHighlighter languaClipboarderge="json" style={bgColor === '#000' ? atomOneDarkReasonable : googlecode}>
        {`{
  "status": "ok",
  "date": "23.03.2020",
  "day": "PONIEDZIAŁEK",
  "is_name": false,
  "name": "",
  "is_first": true,
  "first": "<p><b>Ty jedynie jesteś Panem! Ty stworzyłeś niebiosa, niebiosa niebios i cały ich zastęp, ziemię i wszystko, co jest na niej, morza i wszystko, co jest w nich.</b></p>",
  "first_s": "Ne 9,6",
  "is_second": true,
  "second": "<p>Bóg<b> nie omieszkał dawać o sobie świadectwa przez dobrodziejstwa, dając wam z nieba deszcz i czasy urodzajne, napełniając pokarmem i radością serca wasze.</b></p>",
  "second_s": "Dz 14,17",
  "is_third": true,
  "third": "<p>Wierzę, że Bóg stworzył mnie oraz wszystkie istoty. Dał mi ciało i duszę, oczy, uszy i wszystkie inne części ciała, rozum oraz wszystkie zmysły i utrzymuje mnie przy życiu. Ponadto daje mi ubranie i obuwie, pokarmy i napoje, dom i gospodarstwo, współmałżonka i dzieci, miejsce pracy oraz dobytek i wszystkie dobra. </p>",
  "third_s": "Marcin Luter †1546, Mały katechizm",
  "image": "https://bncd-storage.s3.amazonaws.com/media/images/biblia3.jpg"
}`}
        </SyntaxHighlighter>
      </div>
    </div>
  )
})

const Clipboarder = props => {
  const { children } = props;
  const [ info, setInfo ] = useState(false)
  const onClipboard = useCallback(() => {
    setInfo(true);
    setTimeout(() => setInfo(false), 1000);
  }, [info])

  return (
    <Clipboard
      onSuccess={onClipboard}
      className="special-dock-simplecode"
      data-clipboard-text={children}
    >
      {info ? 'skopiowano do schowka' : children}
    </Clipboard>
  )


}
