
import aw from './1w.svg';
import ar from './1r.svg';
import bw from './2w.svg';
import br from './2r.svg';
import cw from './3w.svg';
import cr from './3r.svg';
import dw from './4w.svg';
import dr from './4r.svg';
import baner from './bncd.png';

const a = {w: aw, r: ar}
const b = {w: bw, r: br}
const c = {w: cw, r: cr}
const d = {w: dw, r: dr}

const dark = '#273483'
const red = '#cd1719'
const light = '#636aaf'
const gray = '#d0d0d0'

const set = [
  [
    { picColor: 'w', bgColor: red },
    { picColor: 'w', bgColor: light },
    { picColor: 'r', bgColor: gray },
    { picColor: 'w', bgColor: dark },
  ],
  [
    { picColor: 'r', bgColor: gray },
    { picColor: 'w', bgColor: light },
    { picColor: 'w', bgColor: dark },
    { picColor: 'w', bgColor: red },
  ],
  [
    { picColor: 'w', bgColor: light },
    { picColor: 'w', bgColor: dark },
    { picColor: 'w', bgColor: red },
    { picColor: 'r', bgColor: gray },
  ],
  [
    { picColor: 'w', bgColor: dark },
    { picColor: 'w', bgColor: red },
    { picColor: 'r', bgColor: gray },
    { picColor: 'w', bgColor: light },
  ],
  [
    { picColor: 'w', bgColor: red },
    { picColor: 'w', bgColor: dark },
    { picColor: 'r', bgColor: gray },
    { picColor: 'w', bgColor: light },
  ],
  [
    { picColor: 'w', bgColor: light },
    { picColor: 'r', bgColor: gray },
    { picColor: 'w', bgColor: red },
    { picColor: 'w', bgColor: dark },
  ],
  [
    { picColor: 'r', bgColor: gray },
    { picColor: 'w', bgColor: red },
    { picColor: 'w', bgColor: light },
    { picColor: 'w', bgColor: dark },
  ],
  [
    { picColor: 'r', bgColor: gray },
    { picColor: 'w', bgColor: light },
    { picColor: 'w', bgColor: dark },
    { picColor: 'w', bgColor: red },
  ],
]

export { a, b, c, d, set, baner };
