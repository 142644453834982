const main = (state = {
  timeScale: 'w', // d, w, m, y
  rState: 'done', // done, request, requestNotToday, requestForce
  dayData: {},
  bookmarks: {},
  globalSpecialVisible: true,
  url: 'https://biblianacodzien.pl',
  sideMenuIcoSize: 50,
  isBgImg: true,
  isDarkMode: true,
  bgColor: '#000',
  fgColor: '#ddd',
  zoom: 1,
  windowWidth: 100,
  rDate: new Date(),
  rangeDate: { start: new Date(2019, 0, 1, 1), stop: new Date(2019, 1, 1) },
  loading: '', // false: free true: busy
  pics: {
    day: [],
    week: [],
    month: [],
    year: []
  },
  specials: {
    subscribe: false,
    payment: false,
    developers: false,
  },
  singleSites: {
    /*slug: {title, html}*/
  },
  multiSites: {
    /*slug: {title, articles: [{title, html, slug}, ...]}*/
  },
  context: {
    /*slug: id*/
  },
  menu: [/* { title, id, slug, sublinks: [{ title, slug, id }] } */],
  modal: {
    text: '',
    content: null,
    time: 0
  },
  loadedSettings: false,
  /*
  dayData: {
      "nazwa": "1. WTOREK",
      "swieta": [
          {
              "nazwa": "Nowy Rok",
              "haslo": {
                  "text": " ... ",
                  "zrodlo": "Kol 3,17"
              },
              "psalm": "Ps 8,2-10",
              "piesni": [
                  {
                      "tytul": "Jezu, hasłem być nam chciej",
                      "zrodlo": "94"
                  },
                  {
                      "tytul": "Imię Jezus",
                      "zrodlo": "787"
                  },
                  {
                      "tytul": "Pójdźmy z podziękowaniem",
                      "zrodlo": "99"
                  }
              ],
              "czytanie_st": "Joz 1,1-9",
              "czytanie_ew": "Łk 4,13-15",
              "czytanie_la": "Jk 4,13-15",
              "czytanie_ps": "",
              "kazania": [],
              "graduale": null,
              "niedziela": true
          }
      ],
      "data": "2019-01-01",
      "rok": {
          "numer": 2019,
          "haslo": {
              "text": " ... ",
              "zrodlo": "1 Mż 9,13"
          }
      },
      "miesiac": {
          "numer": 1,
          "haslo": {
              "text": " ... ",
              "zrodlo": "1 Mż 9,13"
          }
      },
      "tydzien": {
          "numer": 6,
          "haslo": {
              "text": "....",
              "zrodlo": "Ps 66,5"
          },
          "psalm": "Ps 107,1.2.23-32",
          "piesni": [
              {
                  "tytul": "Kto chce, niech szuka innych dróg",
                  "zrodlo": "638"
              },
              {
                  "tytul": "Jezu Zbawco mojej duszy",
                  "zrodlo": "801"
              }
          ]
      },
      "pierwsze": {
          "html": "<p><b>...</b></p>",
          "zrodlo": "2 Mż 23,6"
      },
      "drugie": {
          "html": "<p><b>...</b></p>",
          "zrodlo": "Łk 16,19-20"
      },
      "trzecie": {
          "html": "<p>...</p>",
          "zrodlo": "br. Roger Schütz †2005"
      },
      "rozwazaniehtml": {
          "html": "<p>...</p>",
          "zrodlo": "MCz"
      },
      "rozwazanie": {
          "text": "...",
          "zrodlo": "Prz 16,9"
      },
      "txt1": "...",
      "txt2": "...",
      "dodatek": "..."
    }
  */
}, action) => {
  switch (action.type) {
    case 'SET_MAIN_DATA':
      return { ...state, ...action.data };
    case 'SET_SPECIAL':
      return { ...state, specials: { ...state.specials, [action.name]: action.data }}
    default:
      return state;
  }
};

export default main;
