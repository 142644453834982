
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { Helmet } from 'react-helmet';
import { setMainData } from '../../actions';
import { getSingleArticle, clearHtml } from '../base';


const mapStateToProps = state => ({
  sites: state.main.singleSites,
  context: state.main.context,
  bgColor: state.main.bgColor,
})

const mapDispatchToProps = dispatch => ({
  addSite: data => dispatch(setMainData({ singleSites: data }))
})


export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { slug, sites, context, addSite, offset } = props

  const [bgOpacity, setBgOpacity] = useState(0);

  const title = useMemo(() => (slug in sites ? sites[slug].title : ''), [sites])
  const html = useMemo(() => (slug in sites ? sites[slug].html : ''), [sites])
  const img = useMemo(() => (slug in sites ? sites[slug].img : ''), [sites])

  const loadImg = useCallback(() => setBgOpacity(1))

  useEffect(() => {
    if (!(slug in sites) && (slug in context)) {
      getSingleArticle(
        context[slug],
        site => {
          addSite({
            ...sites,
            [site.slug]: {
              title: site.title,
              html: site.html,
              slug: site.slug,
              img: site.img
            }
          })
          console.log('site', site);
        }
      )
    }
  }, [slug, context, sites])


  return (
    <div className={(isMobile ? 'multi-site-container-mob ' : '') + 'multi-site-container'}>
      <Helmet>
        <meta name="theme-color" content={props.bgColor} />
        <meta name="description" content={clearHtml(html)} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={clearHtml(html)} />
        <title>{title}</title>
        <link rel="canonical" href={`https://biblianacodzien.pl/sites/article/${slug}/`} />
      </Helmet>
      <div className='subarticle-background-image' style={{ opacity: bgOpacity, transform: 'translateX(0%)', backgroundImage: `url(${img})` }}/>
      <img
        className={(isMobile ? '' : 'subarticle-background-image-mob ') + 'subarticle-background-image'}
        src={img}
        style={{ display: 'none' }}
        width='100%' height='auto'
        onLoad={loadImg}
      />
      <div className={(isMobile ? 'subarticle-background-filter-mob ' : '') + 'subarticle-background-filter'} style={{ backgroundColor: '#d0d0d080' }}/>
      <h1 style={{ marginTop: isMobile ? 60 : 160/*isMobile ? 60 : offset*/, marginBottom: '100px' }} className={(isMobile ? 'multi-site-title-mob ' : '') + 'multi-site-title'}>{title}</h1>
      <div
        className={(isMobile ? 'subarticle-site-container-mob ' : '') + 'subarticle-site-container'}
        style={{ backgroundColor: '#273483f0' }}
      >
        <div className={(isMobile ? 'subarticle-content-mob ' : 'single-site-content ') + 'subarticle-content'} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )

})
