
const setMainData = data => ({
  type: 'SET_MAIN_DATA',
  data
});

const setSpecial = (name, data) => ({
  type: 'SET_SPECIAL',
  data,
  name
});


export { setMainData, setSpecial }
