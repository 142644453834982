import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
}

const isIE = false || !!document.documentMode;
if (isIE){
  window.alert(
    "Używasz przeglądarki internet explorer w wersji miedzy 6, a 11, która nie wspiera wszystkich zastosowanych technologii na tej stronie. Sugerujemy instalację najnowszej przeglądarki Edge, lub innyj komercyjnej (Chrome, Firefox, Opera)."
  )
}

specialRedirect();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

function specialRedirect() {
  if (window.location.pathname === "/aplikacja/") {
    window.location.pathname = "/sites/article/aplikacja"
  }
}
