import React from 'react';
import './main.css';
import './parts.css';
import './ocasion.css';
import './modal.css';
import './sidemenu.css';
import './articles.css';
import './article.css';
import './info.css';
import './baner.css';
import './special.css';
import './switch.css';

export default () => {
  return <div/>
};
