import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { setMainData, setSpecial } from 'actions';
import { MdClose } from 'react-icons/md';

const mapStateToProps = (state) => ({
  specials: state.main.specials,
  bgColor: state.main.bgColor,
  fgColor: state.main.fgColor,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setViewed: (name, data) => dispatch(setSpecial(name, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { specials, setState, state, fgColor, bgColor, setViewed, children, name } = props

  const close = useCallback(() => {
    setViewed(name, false);
    window.location.hash = '';
  }, [name, specials, setViewed])

  const handleLocation = useCallback(() => {
    const opt = window.location.hash;
    if (opt === '#payment') {
      setViewed('payment', true);
    } else if (opt === '#subscribe') {
      setViewed('subscribe', true);
    } else if (opt === '#developers') {
      setViewed('developers', true);
    }
  }, [])

  useEffect(() => {
    handleLocation()
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', handleLocation)
    return () => {
      window.removeEventListener('hashchange', handleLocation)
    }
  }, [])

  return (
    <div className='special-dock-window' style={{ borderRadius: specials[name] ? 0 : 1000, backgroundColor: bgColor + 'd', transform: `scale(${specials[name] ? 1 : 0})` }} >
      <MdClose className={"special-dock-close" + (isMobile ? ' special-dock-close-mob' : '')} color={fgColor} size={isMobile ? 35 : 50} onClick={close} />
      {children}
    </div>
  )
})
