
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { setMainData } from '../../actions';
import { a, b, c, d, set } from 'assets/baner';

const mapStateToProps = (state) => ({
  sideMenuIcoSize: state.main.sideMenuIcoSize,
  fgColor: state.main.fgColor,
  bgColor: state.main.bgColor,
});
const mapDispatchToProps = (dispatch) => ({
  setSideMenuIcoSize: data => dispatch(setMainData({sideMenuIcoSize: data})),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {

  const { onChangeOffset, fgColor, bgColor, sideMenuUtil, setSideMenuIcoSize } = props;

  const maxHeight = useMemo(() => (isMobile ? 25 : (((window.innerWidth < 1250 ? window.innerWidth : 1250)*0.8*280)/2290)), []);

  const [ height, setHeight ] = useState(maxHeight);
  //const [ marginTop, setMarginTop ] = useState(isMobile ? 13 : (maxHeight/4));
  const [ marginTop, setMarginTop ] = useState(0);
  const [ bgOpacity, setBgOpacity ] = useState('b')
  const [ banerOpacity, setBanerOpacity ] = useState('ff')

  const listener = useCallback((eve) => {
    const d = maxHeight - window.pageYOffset;
    setHeight(d > 25 ? d : 25);
    onChangeOffset(d > 0 ? d + 100 : 85)
    //onChangeOffset(50)
    setSideMenuIcoSize((d-50) > 35 ? (d-50) : 35)
    if (d < 0) {
      setBgOpacity('0');
      setBanerOpacity('a0');
      //setMarginTop(isMobile ? 13 : (d/4) > 1 ? (d/4) + 10 : 10);
    } else {
      setBgOpacity('b');
      setBanerOpacity('ff');
      //setMarginTop(isMobile ? 13 : (d/4)-10 > 1 ? (d/4)-10 : 10);
    }
  }, [height, banerOpacity, bgOpacity]);

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  useEffect(() => {
    listener();
  }, []);

  return (
    <>
    {/*}<div style={{ height: maxHeight }} />*/}
    <div className='baner-container' /*style={{ backgroundColor: bgColor + bgOpacity }}*/>
      <div style={{ flex: 1 }}/>
      <Baner height={height} opacity={banerOpacity} marginTop={marginTop} />
      <div style={{ flex: 1 }}/>
    </div>
    </>
  )
})


const Baner = props => {

  const { height, opacity, marginTop } = props;

  const [ colors, setColors ] = useState(set[0]);
  const [ iterator, setIterator ] = useState(1);

  const iterLoop = useCallback((it) => {
    setIterator(it);
    setTimeout(() => iterLoop(it+1), 40000)
  }, [iterator]);

  useEffect(() => {
    setColors(set[iterator % 7]);
  }, [iterator]);

  useEffect(() => {
    iterLoop(0);
  }, []);

  return (
    <div className={`baner-bncd-container`} onClick={() => {window.location.pathname = '/'}} style={{ marginTop }} >
      <div className='baner-bncd-element' style={{ backgroundColor: colors[0].bgColor + opacity }}>
        <img className="baner-img" src={a[colors[0].picColor]} height={height} />
      </div>
      <div className='baner-bncd-element' style={{ backgroundColor: colors[1].bgColor + opacity }}>
        <img className="baner-img" src={b[colors[1].picColor]} height={height} />
      </div>
      <div className='baner-bncd-element' style={{ backgroundColor: colors[2].bgColor + opacity }}>
        <img className="baner-img" src={c[colors[2].picColor]} height={height} />
      </div>
      <div className='baner-bncd-element' style={{ backgroundColor: colors[3].bgColor + opacity }}>
        <img className="baner-img" src={d[colors[3].picColor]} height={height} />
      </div>
    </div>
  )
}
