
const saveUtil = (util, val) => {
  const utilities = loadUtilities();
  const sutilities = { ...utilities, [util]: val};
  console.log('save utilities', sutilities);
  localStorage.setItem('utilities', JSON.stringify(sutilities));
}

const loadUtilities = () => {
  const rtn = JSON.parse(localStorage.getItem('utilities'));
  console.log('load utilities', rtn)
  if (!rtn || rtn === null) return {}
  return rtn;
}

export { saveUtil, loadUtilities };
