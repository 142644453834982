
import React, { Component } from 'react';
import { SingleText, SingleAddition, ListAddition } from './';
import { isMobile } from "react-device-detect";

class Ocasion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: false
    }

    this.onClick = this._onClick.bind(this);
  }

  _onClick() {
    this.setState({ view: !this.state.view });
  }

  canExpanded () {
    return [
      "haslo", "psalm", "piesni", "czytanie_st", "czytanie_ew", "czytanie_ps", "kazania", "graduale"
    ].some(i => i in this.props)
  }

  render() {
    const { zoom } = this.props;
    return (
      <div className={isMobile ? 'ocasion-container-mobile' : 'ocasion-container'} style={{ marginBottom: this.state.view ? '10%' : '2%' }}>
        <p
          className='ocasion-title'
          style={{ fontSize: `${(isMobile ? 16 : 24)*zoom}pt`, cursor: this.canExpanded ? 'pointer' : 'arrow', margin: '15px' }}
          onClick={this.onClick}
        >{this.props.name}</p>
        <div className='ocasion-trans' style={{ maxHeight: this.state.view ? '2000px' : 0, opacity: this.state.view ? 1 : 0, transform: `scaleY(${this.state.view ? 1 : 0})`  }}>
          {this.props.dayText ? <div>
            <p className='ocasion-subtitle' style={{ fontSize: `${(isMobile ? 16 : 22)*zoom}pt` }}>Hasło dnia:</p>
            <SingleText
              inner
              text={this.props.dayText.text ? this.props.dayText.text : ''}
              source={this.props.dayText.zrodlo ? this.props.dayText.zrodlo : ''}
            />
          </div>: null}
          {this.props.weekText ? <div>
            <p className='ocasion-subtitle' style={{ fontSize: `${(isMobile ? 16 : 22)*zoom}pt` }}>Hasło tygodnia:</p>
            <SingleText
              inner
              text={this.props.weekText.text ? this.props.weekText.text : ''}
              source={this.props.weekText.zrodlo ? this.props.weekText.zrodlo : ''}
            />
          </div>: null}
          {this.props.weekPsalm ? <SingleAddition
            title='Psalm tygodnia:'
            text={this.props.weekPsalm}
            pointer
          /> : null}
          {this.props.weekSongs.length ? <ListAddition
            title={`Pieś${this.props.weekSongs.length > 1 ? 'ni' : 'ń'} tygodnia:`}
            texts={this.props.weekSongs.map(song => (`${song.tytul ? song.tytul : ''}${' '}${song.zrodlo ? song.zrodlo : ''}`))}
          /> : null}
          {this.props.psalm ? <SingleAddition
            title={'Psalm dnia:'}
            text={this.props.psalm}
            pointer
          /> : null}
          {this.props.songs.length ? <ListAddition
            title={`Pieś${this.props.songs.length > 1 ? 'ni' : 'ń'} dnia:`}
            texts={this.props.songs.map(song => (`${song.tytul ? song.tytul : ''}${' '}${song.zrodlo ? song.zrodlo : ''}`))}
          /> : null}
          {this.props.st ? <SingleAddition
            title={'Stary Testament:'}
            text={this.props.st}
            pointer
          /> : null}
          {this.props.ew ? <SingleAddition
            title={'Ewangelia:'}
            text={this.props.ew}
            pointer
          /> : null}
          {this.props.la ? <SingleAddition
            title={'Lekcja apostolska:'}
            text={this.props.la}
            pointer
          /> : null}
          {this.props.ps ? <SingleAddition
            title={'Psalm:'}
            text={this.props.ps}
            pointer
          /> : null}
          {this.props.kaz.length ? <ListAddition
            title={`Tekst${this.props.kaz.length > 1 ? 'y' : ''} kazaln${this.props.kaz.length > 1 ? 'e' : 'y'}:`}
            texts={this.props.kaz.map(item => (item.zrodlo))}
            pointer
          /> : null}
          {this.props.grad !== null || this.props.grad ? <SingleAddition
            title={'Graduale:'}
            text={this.props.grad}
            pointer
          /> : null}
        </div>
      </div>
    )
  }
}

export default Ocasion;
