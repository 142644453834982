import React, { useState, useCallback, useMemo } from 'react';
import { MdFilterList, MdClose } from 'react-icons/md'
import { setMainData } from '../../actions';
import { isMobile } from "react-device-detect";

export default props => {
  const { data, onChoose, color, bgColor } = props;

  const startSizeSmall = useMemo(() => (window.innerWidth < 1400), []);

  const [ expand, setExpand ] = useState(!(isMobile) && !startSizeSmall);
  const [ view, setView ] = useState(!(isMobile) && !startSizeSmall);

  const onClick = useCallback(slug => () => {
    onChoose(slug);
  }, [data])

  const closeToc = useCallback(() => {
    setExpand(false);
    setView(false)
  }, [expand]);
  const openToc = useCallback(() => {
    setExpand(true);
    setTimeout(() => setView(true), 800)
  }, [expand]);

  return expand ? (
    <div className={isMobile ? 'toc-container-mob' : "toc-container"} style={{ borderColor: color, backgroundColor: bgColor }}>
      {/*<div style={{ display: 'flex', flexDirection: 'row' }}>
        <MdClose size={22} style={{ cursor: 'pointer', marginRight: '10px' }} onClick={closeToc} />
        <h4 className="toc-title" >Przejdź do</h4>
        <div className="toc-glass" />
      </div>*/}
      {data.map(item => (
        <div className="toc-item" key={`toc-item-${item.title}`} style={{ color, opacity: view ? 1 : 0 }} onClick={onClick(item.slug)}>
          <p>{item.title}</p>
        </div>
      ))}
      <MdClose className="toc-close" color={color} size={22} style={{ cursor: 'pointer', marginRight: '10px' }} onClick={closeToc} />
    </div>
  ) : (
    <div onClick={openToc} className={isMobile ? "toc-container-btt-mob" : "toc-container-btt"} style={{ borderColor: color, backgroundColor: bgColor + 'a' }}>
      <MdFilterList color={color} size={isMobile ? 20 : 30} />
    </div>
  )
};
