
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MdMoreVert, MdClose } from 'react-icons/md';
import { setMainData } from '../../actions';
import { loadUtilities } from './control';
import { Zoom, IsPic, Share, DarkLight } from './';
import { isMobile } from "react-device-detect";


const mapStateToProps = (state) => ({
  color: state.main.fgColor,
  bgColor: state.main.bgColor,
});
const mapDispatchToProps = (dispatch) => ({
  setState: data => dispatch(setMainData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { color, bgColor, setState, offset } = props;

  const [ expand, setExpand ] = useState(false)

  const toggleExpand = useCallback(() => {
    setExpand(!Boolean(expand));
  }, [expand])

  return (
    <div className="utilities-menu" style={{ top: (isMobile && expand) ? 50 : (isMobile ? 0 : offset - 60), height: '50px', minWidth: expand ? '285px' : isMobile ? '36px' : '46px', maxWidth: '450px'  }}>
      {expand ? <>
        <Zoom />
        <IsPic />
        <DarkLight />
        <Share pic={props.pic} />
        <div onClick={toggleExpand} className={isMobile ? "util-item-mob" : "util-item"} style={{ borderColor: color, backgroundColor: bgColor + 'a' }}>
          <MdClose color={color} size={isMobile ? 20 : 30} />
        </div>
      </> : <div onClick={toggleExpand} className={isMobile ? "util-item-mob" : "util-item"} style={{ borderColor: color, backgroundColor: bgColor + 'a' }}>
        <MdMoreVert color={color} size={isMobile ? 20 : 30} />
      </div>}
    </div>
  );
});
