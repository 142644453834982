
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { Helmet } from 'react-helmet';
import { setMainData } from '../../actions';
import { getInfo, clearHtml } from '../base';


const mapStateToProps = state => ({
  bgColor: state.main.bgColor,
})


export default connect(mapStateToProps)(props => {
  const { slug, offset } = props

  const [ bgOpacity, setBgOpacity ] = useState(0);
  const [ content, setContent ] = useState('');

  const loadImg = useCallback(() => setBgOpacity(1))

  useEffect(() => getInfo(
    slug,
    info => {
      setContent({ ...info });
      console.log('info', info);
    }
  ), [slug])

  const { img, title, subtitle, text, subtext } = content;

  return !content ? null : (
    <div className={(isMobile ? 'multi-site-container-mob ' : '') + 'multi-site-container'}>
      <Helmet>
        <meta name="theme-color" content={props.bgColor} />
        <meta name="description" content={clearHtml(text)} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={clearHtml(text)} />
        <title>{title}</title>
        <link rel="canonical" href={`https://biblianacodzien.pl/sites/info/${slug}/`} />
      </Helmet>
      <div className='subarticle-background-image' style={{ opacity: bgOpacity, transform: 'translateX(0%)', backgroundImage: `url(${img})` }}/>
      <img
        className={(isMobile ? 'subarticle-background-image-mob ' : '') + 'subarticle-background-image'}
        src={img}
        style={{ display: 'none' }}
        width='100%' height='auto'
        onLoad={loadImg}
      />
      <div className={(isMobile ? 'subarticle-background-filter-mob ' : '') + 'subarticle-background-filter'} style={{ backgroundColor: '#d0d0d080' }}/>
      {/*<div className={(isMobile ? 'info-container-mob ' : '') + 'info-container'}>*/}
      <div
        className={(isMobile ? 'subarticle-site-container-mob ' : '') + 'subarticle-site-container'}
        style={{ marginTop: isMobile ? 60 : 160/*isMobile ? 60 : offset*/, backgroundColor: '#273483f0', flexDirection: 'column', alignItems: 'center', paddingLeft: '5%', paddingRight: '5%' }}
      >
        {title ? <p className={(isMobile ? 'info-site-title-mob ' : '') + 'info-site-title'}>{title}</p> : null}
        {subtitle ? <p className={(isMobile ? 'info-site-subtitle-mob ' : '') + 'info-site-subtitle'}>{subtitle}</p> : null}
        {text ? <div className={(isMobile ? 'info-site-text-mob ' : '') + 'info-site-text'} dangerouslySetInnerHTML={{ __html: text}}/> : null}
        {subtext ? <div className={(isMobile ? 'info-site-subtext-mob ' : '') + 'info-site-subtext'} dangerouslySetInnerHTML={{ __html: subtext}}/> : null}
      </div>
    </div>
  )
})
