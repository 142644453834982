
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setMainData } from '../actions';
import df from 'dateformat';
import { MdErrorOutline, MdChevronLeft, MdChevronRight, MdReply } from 'react-icons/md';
import { isMobile } from "react-device-detect";
import DayPicker from 'react-day-picker';
import { Helmet } from 'react-helmet';
import 'react-day-picker/lib/style.css';
import { loadUtilities } from './utilities/control';
import { Standard, Contemplation, Addons, Ocasion, Week, Month, Year } from './parts';
import Utilities from './utilities';
import Toc from './toc';


const MONTHS = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];
const WEEKDAYS_LONG = [
  'Niedziela',
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota'
];
const WEEKDAYS_SHORT = ['nd.', 'pn.', 'wt.', 'śr.', 'cz.', 'pt.', 'sb.']


class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bgOpacity: 0,
      newPicOpacity: 0,
      picData: 'day',
      addon: false,
      wasAddon: false,
      dayPick: false,
      allVisible: false,
      oldPic: '',
      newPic: '',
      pic: {pic: '', author: ''},
      bufferPic: {pic: '', author: ''},
      picOpacity: 0,
      dateBackgroudOpacity: '00',
      snapToOnRefresh: ''
    };
    this.seed = Math.random();
    this.sectionRefs = {
      mainWindow: React.createRef(),
      week: React.createRef(),
      month: React.createRef(),
      year: React.createRef(),
      standard: React.createRef(),
      contemp: React.createRef(),
      addonTxt: React.createRef(),
      addon: React.createRef(),
    }
    this.secParser = {
      dzien: 'mainWindow',
      bncd: 'standard',
      tydzien: 'week',
      miesiac: 'month',
      rok: 'year',
      rozwazanie: 'contemp',
      main: 'mainWindow',
      sandard: 'standard',
      week: 'week',
      month: 'month',
      year: 'year',
      contemp: 'contemp',
    }

    this.onTocChoose = this.onTocChoose.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.clickLeft = this.clickLeft.bind(this);
    this.clickRight = this.clickRight.bind(this);
    this.comeBack = this.comeBack.bind(this);
    this.slideOnToc = this.slideOnToc.bind(this);

    this.allPics = {}
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenToScroll.bind(this));
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.setState({ allVisible: true })
    this.checkUrl();
    this.loadUtilities();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.state.pics !== this.props.state.pics) {
      this.allPics = {
        year: this.getRandomPic(this.props.state.pics['year']),
        month: this.getRandomPic(this.props.state.pics['month']),
        week: this.getRandomPic(this.props.state.pics['week']),
        day: this.getRandomPic(this.props.state.pics['day']),
      }
      //this.setState({ bgOpacity: 0 })
      this.setState({
        picOpacity: this.allPics.day.pic !== prevState.bufferPic.pic || !this.props.state.isBgImg ? 0 : 1,
        bufferPic: {...this.allPics.day}
      })
      //setTimeout(() => this.setState({ newPicOpacity: this.props.state.isBgImg ? 1 : 0, newPic: this.allPics.day }), 1000)
    }
    if (this.props.state.dayData !== prevProps.state.dayData) {
      this.setState({ allVisible: true });
      if (this.state.snapToOnRefresh) {
        this.snapTo(this.state.snapToOnRefresh);
        this.setState({ snapToOnRefresh: '' })
      }
    }
    if (prevProps.state.isBgImg !== this.props.state.isBgImg) {
      if (this.props.state.isBgImg) {
        this.setState({ picOpacity: 1 });
      } else {
        this.setState({ picOpacity: 0 });
      }
    }
    if (prevState.bufferPic.pic !== this.state.bufferPic.pic && this.props.state.isBgImg) {
      this.setState({ picOpacity: 0 });
      setTimeout(() => this.setState({ pic: { ...this.state.bufferPic } }), 1200);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll.bind(this));
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  loadUtilities() {
    const load = loadUtilities();
    let zoom = 1;
    let isDarkMode = true;
    let isBgImg = true;
    let bgColor = this.props.state.bgColor;
    let fgColor = this.props.state.fgColor;
    if (load.zoom !== undefined) zoom = load.zoom;
    if (load.bgImg !== undefined) isBgImg = load.bgImg;
    if (load.darkMode !== undefined) {
      isDarkMode = load.darkMode;
      bgColor = load.darkMode ? this.props.state.bgColor : this.props.state.fgColor;
      fgColor = load.darkMode ? this.props.state.fgColor : this.props.state.bgColor;
    }
    this.props.setState({ zoom, isDarkMode, bgColor, fgColor, isBgImg });
  }

  checkUrl() {
    const path = window.location.pathname.split('/');
    if (path.length === 4) {
      try {
        const date = new Date(path[1])
        const section = this.secParser[path[2]]
        this.props.setState({ rDate: date, request: 'requestNotToday' });
        this.setState({ snapToOnRefresh: section})
      } finally {
        console.log('loaded');
      }
    }
  }

  snapTo(section) {
    console.log(this.sectionRefs)
    window.scrollTo({
      top: this.sectionRefs[section].current.offsetTop - 80,
      left: 0,
      behavior: 'smooth'
    })
  }

  handleDayClick(date) {
    this.props.setState({ rDate: date, rState: 'requestNotToday' })
    this.setState({ dayPick: false, allVisible: false })
  }

  clickLeft() {
    this.props.setState({ rDate: new Date( this.props.state.rDate.getTime() - 86400001), rState: 'requestNotToday' });
  }

  clickRight() {
    this.props.setState({ rDate: new Date( this.props.state.rDate.getTime() + 86400001), rState: 'requestNotToday' });
  }

  comeBack() {
    this.props.setState({ rDate: new Date(), rState: 'requestNotToday' });
  }

  onTocChoose(pos) {
    window.scrollTo({
      top: pos,
      left: 0,
      behavior: 'smooth'
    });
  }

  getTocData() {
    const data = this.props.state.dayData;
    //const winOffset = (window.innerHeight / -2) + 100;
    const rtn = [
      { title: 'Początek', slug: 'start' }
    ];
    if (data.pierwsze) rtn.push({ title: 'Biblia na co dzień', slug: 'standard' })
    if (data.rozwazanie) rtn.push({ title: 'Rozważanie', slug: 'contemp' })
    if (data.tydzien) rtn.push({ title: 'Hasło tygodnia', slug: 'week' })
    if (data.miesiac) rtn.push({ title: 'Hasło miesiąca', slug: 'month'})
    if (data.rok) rtn.push({ title: 'Hasło roku', slug: 'year' })
    return rtn;
  }

  slideOnToc(slug) {
    if (slug === 'start') this.onTocChoose(0);
    else this.onTocChoose(this.sectionRefs[slug].current.offsetTop-80);
  }

  changePictureData(picData) {
    this.setState({ bgOpacity: this.props.state.isBgImg ? 1 : 0 })
    //setTimeout(() => this.setState({ picData, bgOpacity: 0 }), 2000)
  }

  listenToScroll_old() {
    const winOffset = (window.innerHeight / 2) + 200;
    if (window.pageYOffset + winOffset > this.sectionRefs.year.current.offsetTop) {
      if (this.state.picData !== 'year') this.changePictureData('year')
    } else if (window.pageYOffset + winOffset > this.sectionRefs.month.current.offsetTop) {
      if (this.state.picData !== 'month') this.changePictureData('month')
    } else if (window.pageYOffset + winOffset > this.sectionRefs.week.current.offsetTop) {
      if (this.state.picData !== 'week') this.changePictureData('week')
    } else if ( this.state.picData !== 'day' ) {
      this.changePictureData('day')
    }
  }


  listenToScroll() {
    const winOffset = (window.innerHeight / 2) + 200;
    if (window.pageYOffset + winOffset > this.sectionRefs.year.current.offsetTop) {
      //if (this.state.picData !== 'year') this.setState({newPic: this.allPics.year})
      if (this.state.picData !== 'year' && 'year' in this.allPics) this.setState({
        bufferPic: {...this.allPics.year}
      })
    } else if (window.pageYOffset + winOffset > this.sectionRefs.month.current.offsetTop) {
      //if (this.state.picData !== 'month') this.setState({newPic: this.allPics.month})
      if (this.state.picData !== 'month' && 'month' in this.allPics) this.setState({
        bufferPic: {...this.allPics.month}
      })
    } else if (window.pageYOffset + winOffset > this.sectionRefs.week.current.offsetTop) {
      //if (this.state.picData !== 'week') this.setState({newPic: this.allPics.week})
      if (this.state.picData !== 'week' && 'week' in this.allPics) this.setState({
        bufferPic: {...this.allPics.week}
      })
    } else if ( 'day' in this.allPics ) {
      //this.setState({newPic: this.allPics.day})
      this.setState({
        bufferPic: {...this.allPics.day}
      })
    }
    //setTimeout(() => this.setState({ oldPic: String(this.state.newPic) }), 2500)
  }

  updateDimensions() {
    this.props.setState({ windowWidth: window.innerWidth });
  }

  getRandomPic(pics) {
    return pics[parseInt(this.seed*pics.length)];
  }

  colorHexToNakedDec(col) {
    const c = col.split('').filter(i => i !== '#').map(item => ((parseInt(item, 16) + 1) * 16 - 1))
    return c.join(', ')
  }

  isTodayDate() {
    const current = this.props.state.rDate;
    const today = new Date();
    return (today.getDay() === current.getDay() && today.getMonth() === current.getMonth() && today.getYear() === current.getYear())
  }

  render() {
    const { zoom } = this.props.state;
    const data = this.props.state.dayData;
    const pic = this.getRandomPic(this.props.state.pics[this.state.picData]);
    const decBgColor = this.colorHexToNakedDec(this.props.state.bgColor);
    return (
      <div ref={this.sectionRefs.mainWindow} className='main-trans' style={{ color: this.props.state.fgColor, opacity: this.state.allVisible ? 1 : 0 }}>
        <Helmet>
          <meta name="theme-color" content={this.props.state.bgColor} />
          <meta name="description" content={data.tydzien ? `Hasło tygodnia: ${data.tydzien.haslo.text}  ${data.tydzien.haslo.zrodlo}` : 'Wierzę, pomóż niedowiarstwu memu.  Mk 9,24'} />
          <meta property="og:title" content="Hasła i rozważania biblijne na każdy dzień" />
          <meta property="og:description" content={data.tydzien ? `Hasło tygodnia: ${data.tydzien.haslo.text}  ${data.tydzien.haslo.zrodlo}` : 'Wierzę, pomóż niedowiarstwu memu.  Mk 9,24'} />
          <title>Biblia na co dzień - Hasła i rozważania biblijne na każdy dzień</title>
          <link rel="canonical" href="https://biblianacodzien.pl" />
        </Helmet>
        <Toc
          data={this.getTocData()}
          onChoose={this.slideOnToc}
          color={this.props.state.fgColor}
          bgColor={this.props.state.bgColor}
        />
        <Utilities pic={pic} offset={this.props.offset} />
        <MdReply
          className='comeback-button'
          onClick={this.comeBack}
          size={(isMobile ? 20 : 30)}
          style={{ transform: `scale(${this.isTodayDate() ? 0 : 1})`, top: isMobile ? 100 : (20 + this.props.offset), backgroundColor: this.props.state.bgColor + 'a' }}
          color={this.props.state.fgcolor}
        />
        <div className="background-container" style={{ backgroundColor: '#222' }}>{/*this.props.state.bgColor }}>*/}
          <div className="background-image-divider" style={{ opacity: this.state.picOpacity, backgroundImage: `url(${this.state.pic.pic})` }}/> : null
          <img src={this.state.pic.pic} className="background-image" onLoad={() => this.setState({ picOpacity: 1 })}/>
          {/*<div className="background-image-container">
            <div className="background-image-divider" style={{ opacity: this.state.newPicOpacity, backgroundImage: `url(${this.state.newPic})` }}/>
            <img src={this.state.newPic} className="background-image" onLoad={() => this.setState({ newPicOpacity: 1 })}/>
          </div>
          <div className="background-image-container">
            <div className="background-image-divider" style={{ opacity: this.state.bgOpacity, backgroundImage: `url(${this.state.oldPic})` }}/>
            <img src={this.state.oldPic} className="background-image" onLoad={() => this.setState({ bgOpacity: 1 })} />
          </div>*/}
        </div>
        <div
          className="background-container"
          style={{ backgroundImage: `linear-gradient(rgba(${decBgColor}, .6) 0%,rgba(${decBgColor},.8) 45%,rgba(${decBgColor},.7) 70%, rgba(${decBgColor},.2) 100%)` }}
        />
        <div className='image-backgroud-author' style={{ opacity: this.state.picOpacity - 0.6, fontSize: 14, fontWeight: 'bold', color: this.props.state.fgColor }}>
          <p>fot:  <a href={this.state.pic.authorhref}>{this.state.pic.author}</a></p>
        </div>
        <div>
          <div className='head-title-container' style={{ marginTop: isMobile ? 110 : 260/*isMobile ? 100 : this.props.offset*/ }}>
            <div
              className='calendar-container'
              style={{ width: this.state.dayPick ? 300 : 250 * zoom, height: this.state.dayPick ? 380 : 50 * zoom, borderColor: this.props.state.fgColor, backgroundColor: (this.props.state.bgColor + this.state.dateBackgroudOpacity) }}
              onMouseEnter={() => this.setState({dateBackgroudOpacity: 'f'})}
              onMouseLeave={() => this.setState({dateBackgroudOpacity: '0'})}
            >
              {this.state.dayPick ? <>
                <DayPicker
                  onDayClick={this.handleDayClick}
                  locale="pl"
                  months={MONTHS}
                  weekdaysLong={WEEKDAYS_LONG}
                  weekdaysShort={WEEKDAYS_SHORT}
                />
                <p onClick={() => this.setState({ dayPick: false })} className='calendar-button' >Zamknij</p>
              </> : <p
                className='head-date'
                style={{ borderColor: this.props.state.fgcolor, fontSize: `${30*zoom}pt` }}
                onClick={() => this.setState({ dayPick: true })}
              >{df(this.props.state.rDate, 'd.mm.yyyy')}</p>}
            </div>
            <div className='head-name-container'>
              <MdChevronLeft
                className='day-arrow'
                size={60*zoom}
                color={this.props.state.fgcolor}
                onClick={this.clickLeft}
              />
              <p style={{ fontSize: `${(isMobile ? 20 : 30)*zoom}pt`, textAlign: 'center', flex: 2 }}>{data.nazwa}</p>
              <MdChevronRight
                className='day-arrow'
                size={60*zoom}
                color={this.props.state.fgcolor}
                onClick={this.clickRight}
              />
            </div>
            {data.dodatek ? <MdErrorOutline
              id={this.state.wasAddon ? 'add-stop' : 'add-icon'}
              className={this.state.wasAddon ? 'add-stop' : 'add-icon'}
              color={this.props.state.fgcolor}
              size={60*zoom}
              style={{ margin: '15px' }}
              onClick={() => this.setState({ wasAddon: true, addon: !this.state.addon })}
            /> : null}
            <div
              className='addon-cont'
              style={{
                maxHeight: this.state.addon ? '4000px' : 0,
                opacity: this.state.addon ? 1 : 0,
                margin: this.state.addon ? '2%' : 0,
                zIndex: this.state.addon ? 1 : -2
              }}
              ref={this.sectionRefs.addon}
              dangerouslySetInnerHTML={{ __html: data.dodatek }} />
            {data.swieta ? data.swieta.map(item => <Ocasion
              key={`ocasion-${item.nazwa}`}
              name={item.nazwa}
              weekText={item.niedziela ? data.tydzien.haslo : undefined}
              weekPsalm={item.niedziela ? data.tydzien.psalm : undefined}
              weekSongs={item.niedziela ? data.tydzien.piesni : []}
              dayText={item.haslo}
              psalm={item.psalm}
              songs={item.piesni}
              st={item.czytanie_st}
              ew={item.czytanie_ew}
              la={item.czytanie_la}
              ps={item.czytanie_ps}
              kaz={item.kazania}
              grad={item.graduale}
              zoom={zoom}
            />) : null}
          </div>
          <div ref={this.sectionRefs.standard} style={{ minHeight: window.innerHeight + 100 }}>
            <Standard
              pierwsze={data.pierwsze ? data.pierwsze : {}}
              drugie={data.drugie ? data.drugie : {}}
              trzecie={data.trzecie ? data.trzecie : {}}
            />
            <Addons
              txt1={data.txt1}
              txt2={data.txt2}
            />
          </div>
          <div ref={this.sectionRefs.contemp} style={{ minHeight: (data.rozwazaniehtml || data.rozwazanie) ? window.innerHeight + 100 : 0 }}>
            <Contemplation
              contemplation={data.rozwazaniehtml ? data.rozwazaniehtml : {}}
              text={data.rozwazanie ? data.rozwazanie : {}}
            />
          </div>
          <div ref={this.sectionRefs.week}>{data.tydzien ? <Week data={data.tydzien} onTitleClick={sec => this.snapTo(sec)}/> : null}</div>
          <div ref={this.sectionRefs.month}>{data.miesiac ? <Month data={data.miesiac} onTitleClick={sec => this.snapTo(sec)}/> : null}</div>
          <div ref={this.sectionRefs.year}>{data.rok ? <Year data={data.rok} onTitleClick={sec => this.snapTo(sec)}/> : null}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: state.main
});

const mapDispatchToProps = (dispatch) => ({
  setState: data => dispatch(setMainData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
