
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { MdBrightness6 } from 'react-icons/md'
import { setMainData } from '../../actions'
import { saveUtil, loadUtilities } from './control';
import { isMobile } from "react-device-detect";


const mapStateToProps = (state) => ({
  color: state.main.fgColor,
  bgColor: state.main.bgColor,
  isDarkMode: state.main.isDarkMode
});
const mapDispatchToProps = (dispatch) => ({
  setIsDarkMode: data => dispatch(setMainData({ isDarkMode: data })),
  setColors: ({ bgColor, fgColor }) => dispatch(setMainData({ bgColor, fgColor }))
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { color, bgColor, isDarkMode, setIsDarkMode, setColors } = props;

  const toggleColor = useCallback(() => {
    setColors({bgColor: String(color), fgColor: String(bgColor)});
    saveUtil('darkMode', !Boolean(isDarkMode));
    setIsDarkMode(!Boolean(isDarkMode));
  }, [bgColor, color, isDarkMode])

  return (
    <div className={isMobile ? "util-item-mob" : "util-item"} style={{ borderColor: color, backgroundColor: bgColor + 'a' }} onClick={toggleColor}>
      <MdBrightness6 color={color} size={isMobile ? 20 : 30} />
    </div>
  );
});
