
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MdLink } from 'react-icons/md';
import df from 'dateformat';
import { isMobile } from "react-device-detect";
import { SingleText } from './';
import { setMainData } from '../../actions';
import Clipboard from 'react-clipboard.js';

const mapStateToProps = (state) => ({
  url: state.main.url,
  date: state.main.rDate,
  zoom: state.main.zoom,
  fgColor: state.main.fgColor
});
const mapDispatchToProps = (dispatch) => ({
  setModal: data => dispatch(setMainData({ modal: data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {

  const { setModal, url, date, zoom, fgColor } = props;

  const [ showCopy, setShowCopy ] = useState(false);
  const setValueToShowCopy = useCallback(bool => () => setShowCopy(bool), [showCopy]);
  const onClipboard = useCallback(() => setModal({text: 'skopiowano odnośnik hasła do schowka', time: 3000}), [])

  const text = {
    text: props.text.text ? props.text.text : '',
    source: props.text.zrodlo ? props.text.zrodlo : ''
  }

  const contemplation = {
    text: props.contemplation.html ? props.contemplation.html : '',
    source: props.contemplation.zrodlo ? props.contemplation.zrodlo : ''
  }

  return (props.text.text && props.contemplation.html) ? (
    <div className="contemplation-container" style={{ fontSize: isMobile ? '4vw' : '2vw' }}>
      <div
        onMouseEnter={setValueToShowCopy(true)}
        onMouseLeave={setValueToShowCopy(false)}
        className='longterm-title-container'
      >
        {(showCopy) ? <Clipboard
          data-clipboard-text={`${url}/${df(date, 'yyyy-mm-dd')}/contemp/`}
          onSuccess={onClipboard}
          className='link-btt'
        >
          <MdLink size={60*zoom} color={fgColor} />
        </Clipboard> : null}
        <h2 className="longterm-title">Rozważanie</h2>
      </div>
      <SingleText
        text={text.text}
        source={text.source}
      />
      <SingleText
        text={contemplation.text}
        source={contemplation.source}
      />
    </div>
  ) : null
})
