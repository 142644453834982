
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { Helmet } from 'react-helmet';
import { MdLink } from 'react-icons/md';
import { setMainData } from '../../actions';
import { getMultiArticle, clearHtml } from '../base';
import Toc from '../toc';
import Clipboard from 'react-clipboard.js';


const mapStateToProps = state => ({
  sites: state.main.multiSites,
  context: state.main.context,
  mainFgColor: state.main.fgColor,
  mainBgColor: state.main.bgColor,
  url: state.main.url,
  bgColor: state.main.bgColor,
})

const mapDispatchToProps = dispatch => ({
  addSite: data => dispatch(setMainData({ multiSites: data })),
  setModal: data => dispatch(setMainData({ modal: data })),
})


export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { slug, sites, context, addSite, mainFgColor, mainBgColor, url, setModal, offset } = props

  const [ slugToSlide, setSlugToSlide ] = useState('');
  const [ htmlToHelm, setHtmlToHelm ] = useState({title: '', description: '', slug: ''});

  const title = useMemo(() => (slug in sites ? sites[slug].title : ''), [sites])
  const articles = useMemo(() => (slug in sites ? sites[slug].articles : []), [sites])
  const tocList = useMemo(() => {
    const rtn = [
      { title: 'Początek', slug: 'start' }
    ];
    articles.forEach(item => rtn.push({ title: item.title, slug: item.slug }))
    return rtn;
  }, [sites])

  const setHelm = useCallback(html => setHtmlToHelm(html));
  const slideOnToc = useCallback(slug => {
    if (slug === 'start') window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    else setSlugToSlide(slug);
  })

  useEffect(() => {
    if (!(slug in sites) && (slug in context)) {
      getMultiArticle(
        context[slug],
        site => addSite({ ...sites, [site.slug]: {
          title: site.title,
          articles: site.articles,
        }}),
        () => {}
      )
    }
    if (!window.location.pathname.split('/')[4]) {
      setHelm({description: 'Hasła i rozważania biblijne na każdy dzień', title})
    }
  }, [slug, context, sites, title])

  return (
    <div className={(isMobile ? 'multi-site-container-mob ' : '') + 'multi-site-container'}>
      <Helmet>
        <meta name="theme-color" content={props.bgColor} />
        <meta name="description" content={htmlToHelm.description} />
        <meta property="og:title" content={htmlToHelm.title} />
        <meta property="og:description" content={htmlToHelm.description} />
        <title>{htmlToHelm.title}</title>
        <link rel="canonical" href={`https://biblianacodzien.pl/sites/articles/${slug}/${htmlToHelm.slug}`} />
      </Helmet>
      <Toc
        data={tocList}
        onChoose={slideOnToc}
        color={'#fff'}
        bgColor={mainBgColor}
      />
      <h1 style={{ marginTop: isMobile ? 60 : 160/*isMobile ? 60 : offset*/ }} className={(isMobile ? 'multi-site-title-mob ' : '') + 'multi-site-title'}>{title}</h1>
      {articles.map((article, index) => {
        const bgColor = (index+1) % 3 === 0 ? '#273483' : (index+1) % 2 === 0 ? '#cd1719' : '#636aaf'
        return (
          <SimpleFrame html={article.html} title={article.title} setHelm={setHelm} bgColor={bgColor} slug={article.slug} slugToSlide={slugToSlide} image={article.img}>
            <Title
              setModal={setModal}
              mainslug={slug}
              slug={article.slug}
              fgColor={mainFgColor}
              url={url}
            >{article.title}</Title>
            <div style={{ backgroundColor: bgColor }} className={(isMobile ? 'subarticle-content-mob ' : '') + 'subarticle-content'} dangerouslySetInnerHTML={{ __html: article.html }} />
          </SimpleFrame>
        )}
      )}
    </div>
  )

})


const SimpleFrame = props => {
  const { bgColor, children, slug, slugToSlide, image, setHelm, title, html } = props;

  const [bgOpacity, setBgOpacity] = useState(0);

  const loadImg = useCallback(() => setBgOpacity(1))

  const ref =  useRef();

  useEffect(() => {
    if (window.location.pathname.split('/')[4] === slug) {
      setHelm({ title, description: clearHtml(html), slug });
      window.scrollTo({
        top: ref.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [slug])

  useEffect(() => {
    if (slug === slugToSlide) {
      window.scrollTo({
        top: ref.current.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [ slugToSlide ])

  return (
    <div
      ref={ref}
      className={(isMobile ? 'subarticle-site-container-mob ' : '') + 'subarticle-site-container'}
      style={{ backgroundColor: bgColor }}
    >
      <div
        className={(isMobile ? 'subarticle-background-image-mob ' : '') + 'subarticle-background-image'}
        style={{ opacity: bgOpacity, backgroundImage: `url(${image})` }}
      />
      <img
        className={(isMobile ? 'subarticle-background-image-mob ' : '') + 'subarticle-background-image'}
        src={image}
        style={{ display: 'none' }}
        width='100%' height='auto'
        onLoad={loadImg}
      />
      <div className={(isMobile ? 'subarticle-background-filter-mob ' : '') + 'subarticle-background-filter'} style={{ backgroundColor: bgColor + 'a0' }}/>
      {children}
    </div>
  )
}


const Title = props => {
  const { children, setModal, mainslug, slug, fgColor, url } = props;

  const [ showCopy, setShowCopy ] = useState(false);

  const setCopy = useCallback(bool => () => setShowCopy(bool), [showCopy]);
  const onClipboard = useCallback(() => setModal({text: 'skopiowano odnośnik atykułu do schowka', time: 2000}), [])

  return (
    <div
      onMouseEnter={setCopy(true)}
      onMouseLeave={setCopy(false)}
      className={(isMobile ? 'subarticle-title-mob ' : '') + 'subarticle-title'}
    >
      <h1>{children}</h1>
      {showCopy ? <Clipboard
        data-clipboard-text={`${url}/sites/articles/${mainslug}/${slug}`}
        onSuccess={onClipboard}
        className='link-btt'
      >
        <MdLink size={60} color={fgColor} />
      </Clipboard> : null}
    </div>
  )
}
