
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import longSources from './longSources.json';

const fullSource = src => {
  let rtn = String(src)
  Object.keys(longSources).forEach(item => {
    rtn = src.includes(item) ? src.replace(item, longSources[item]) : rtn;
  });
  return rtn;
};

const mapStateToProps = (state) => ({
  zoom: state.main.zoom,
});

export default connect(mapStateToProps)(props => {
  const { title, text, zoom, pointer } = props;
  const [ longSource, setLongSource ] = useState(false);

  return (
    <div
      style={{ fontSize: `${(isMobile ? 16 : 24)*zoom}pt` }}
      className={isMobile ? 'addition-container-mobile' : 'addition-container'}
    >
      <p className='addition-title'>{title ? title : ''}</p>
      <p
        className={pointer ? 'addition-text-pointer' : 'addition-text'}
        onClick={() => setLongSource(!longSource)}
      >
        {text ? ( longSource ? fullSource(text) : text ) : null}
      </p>
    </div>
  )
})
