
import React, { useState } from 'react';
import { connect } from 'react-redux';
import longSources from './longSources.json';
import { isMobile } from "react-device-detect";

const fullSource = src => {
  let rtn = String(src)
  Object.keys(longSources).forEach(item => {
    rtn = src.includes(item) ? src.replace(item, longSources[item]) : rtn;
  });
  return rtn;
};

const mapStateToProps = (state) => ({
  zoom: state.main.zoom,
});

export default connect(mapStateToProps)(props => {
  const { txt1, txt2, zoom, longterm } = props;
  const [ longSource1, setLongSource1 ] = useState(false);
  const [ longSource2, setLongSource2 ] = useState(false);

  return (txt1 && txt2) ? (
    <div className='txt-container'>
      <p
        className='txt-item'
        style={{ fontSize: `${(isMobile ? 16 : 24)*zoom}pt` }}
        onClick={() => setLongSource1(!longSource1)}
      >{txt1 ? (longSource1 ? fullSource(txt1) : txt1) : ''}</p>
      <p
        className='txt-item'
        style={{ fontSize: `${(isMobile ? 16 : 24)*zoom}pt` }}
        onClick={() => setLongSource2(!longSource2)}
      >{txt2 ? (longSource2 ? fullSource(txt2) : txt2) : ''}</p>
    </div>
  ) : null
})
