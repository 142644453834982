
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { setMainData, setSpecial } from '../../actions';
import { baner as imgBaner } from 'assets/baner';
import { MdKeyboardArrowLeft, MdKeyboardArrowDown, MdMenu, MdMailOutline, MdCode, MdPayment } from 'react-icons/md';
import { getLinks } from '../base';


const apiArt = '/sites/article';
const apiSub = '/sites/articles';

//const apiArt = 'http://localhost:3000/sites/article';
//const apiSub = 'http://localhost:3000/sites/articles';


const mapStateToProps = (state) => ({
  sideMenuIcoSize: state.main.sideMenuIcoSize,
  fgColor: state.main.fgColor,
  bgColor: state.main.bgColor,
});
const mapDispatchToProps = (dispatch) => ({
  setSideMenuIcoSize: data => dispatch(setMainData({sideMenuIcoSize: data})),
  setGlobalSpecialVisible: data => dispatch(setMainData({globalSpecialVisible: data})),
  setViewed: (name, data) => dispatch(setSpecial(name, data))
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {

  const { sideMenuIcoSize, setGlobalSpecialVisible, fgColor, bgColor, offset, setViewed } = props;

  const [ isOpen, setIsOpen ] = useState(false);
  const [ isViewed, setIsViewed ] = useState(false);
  const [ links, setLinks ] = useState([])
  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const setSpecial = useCallback(name => () => {
    setIsOpen(false);
    setViewed(name, true);
  }, [setViewed])

  useEffect(() => getLinks(
    data => {
      setLinks(data);
      console.log('links', data);
    },
    () => console.log('error')
  ), [getLinks])

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setIsViewed(false), 500);
      setGlobalSpecialVisible(true);
    }
    else {
      setIsViewed(true);
      setGlobalSpecialVisible(false);
    }
  }, [isOpen])

  return (
    <div
      className={'sm-container'+(isOpen ? ' sm-opened' : ' sm-closed')}
      style={{
        paddingTop: 10,
        backgroundImage: `linear-gradient(${bgColor} 0%, ${bgColor+'e'} 30%, ${bgColor+(isMobile ? 'e' : 'a')} 80%, ${bgColor+(isMobile ? 'e' : '8')} 100%)`
      }}
    >
      {isOpen !== isViewed ? null : <div
        style={{
          transform: `translateX(${ isOpen ? 0 : window.innerWidth < 360 ? window.innerWidth : (isMobile ? 360 : 380) }px)`,
          borderColor: isOpen ? undefined : fgColor,
          backgroundColor: bgColor + 'a',
          marginTop: isMobile ? 0 : (isOpen ? 0 : offset - 63)
        }}
        className={'sm-button-container'+(isOpen ? ' opened-arrow' : ' closed-menu')}
        onClick={toggleOpen}
      >
        {isOpen ? <MdKeyboardArrowLeft color={fgColor} size={isMobile ? 20 : 35}/> : <MdMenu color={fgColor} size={isMobile ? 20 : 30} />}
      </div>}
      <div className='sm-link-container'>
        {isMobile ? null : <div className='sm-baner-image-container' onClick={() => {window.location.pathname = '/'}}>
          <img src={imgBaner} className={'sm-baner-image' + (isOpen ? ' sm-baner-image-roll' : '')} width={500} />
          <div className='sm-baner-image-smoke'/>
        </div>}
        {isViewed ? links.map(link => (
          <div key={link.slug} className='sm-linkname-container'>
            <a style={{ color: fgColor }} className='sm-link' href={link.sublinks.length ? `${apiSub}/${link.slug}` : `${apiArt}/${link.slug}`}>{link.title}</a>
            <div className='sm-sub-container'>
              {link.sublinks.length ? link.sublinks.map(sub => <a
                key={sub.slug}
                className='sm-link sm-sub'
                href={`${apiSub}/${link.slug}/${sub.slug}/`}
                style={{ color: fgColor }}
              >{sub.title}</a>) : null}
            </div>
          </div>
        )) : null}
        <div onClick={setSpecial('subscribe')} className='sm-linkname-container'style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <MdMailOutline style={{ marginRight: 10 }} size={25} color={fgColor} />
          <p style={{ color: fgColor }} className='sm-link'>Subskrybcja na e-mail</p>
        </div>
        <div onClick={setSpecial('developers')} className='sm-linkname-container' style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <MdCode style={{ marginRight: 10 }} size={25} color={fgColor} />
          <p style={{ color: fgColor }} className='sm-link'>Wstaw skrypt na swoją stronę</p>
        </div>
        <div onClick={setSpecial('payment')} className='sm-linkname-container'style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <MdPayment style={{ marginRight: 10 }} size={25} color={fgColor} />
          <p style={{ color: fgColor }} className='sm-link'>Wesprzyj projekt</p>
        </div>
      </div>
    </div>
  )
})
