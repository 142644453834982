import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import df from 'dateformat';
import { setMainData } from '../actions';

const generate = () => {
  const now = new Date();
  const fix = Number(process.env.REACT_APP_fix);
  const date = new Date(now.getTime() + (now.getTimezoneOffset() * Number(process.env.REACT_APP_offset)));
  const delta = [
    Number(process.env.REACT_APP_k1),
    Number(process.env.REACT_APP_k2),
    Number(process.env.REACT_APP_k3),
    Number(process.env.REACT_APP_k4),
    Number(process.env.REACT_APP_k5)
  ];
  const ultimator = date.getMinutes() + delta[0];
  const rtn = [
    (Math.log(ultimator) / Math.log(Number(process.env.REACT_APP_step)))
      .toFixed(fix).split('.')[1],
    (Math.log(date.getHours() + delta[1]) / Math.log(ultimator))
      .toFixed(fix).split('.').join(''),
    (Math.log(date.getDate() + delta[2]) / Math.log(ultimator))
      .toFixed(fix).split('.').join(''),
    (Math.log(date.getMonth() + 1 + delta[3]) / Math.log(ultimator))
      .toFixed(fix).split('.').join(''),
    (Math.log(date.getFullYear() + delta[4]) / Math.log(ultimator))
      .toFixed(fix).split('.').join(''),
  ];
  return rtn.join('');
};

class Base extends Component {
  constructor(props) {
    super(props);

    this.hosting = 'https://db.bncd.stream/bncd/api';
    this.days = {
      0: 'Niedziela',
      1: 'Poniedziałek',
      2: 'Wtorek',
      3: 'Środa',
      4: 'Czwartek',
      5: 'Piątek',
      6: 'Sobota',
    }
  }

  componentDidMount() {
    this.setMenu();
    this.init();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.state.rState !== this.props.state.rState) return true;
    return false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.state.rState !== this.props.state.rState
      && this.props.state.rState.includes('request')) {
      this.loadDay();
    }
  }

  getCode() {
    return generate();
  }

  async init() {
    await this.updateBaseRange();
    await this.loadBookmarks();
    //this.props.setState({ rState: 'request', rDate: new Date('2019-01-27') });
    this.props.setState({ rState: 'request' });
  }

  downloadPics(date) {
    axios({
      method: 'POST',
      url: `${this.hosting}/get-images/`,
      headers: {
        validator: this.getCode()
      },
      data: {
        date
      }
    })
    .then(resp => {
      if (resp && resp.data.status === 'ok') {
        this.props.setState({ pics: resp.data.data })
      }
    })
    .catch(err => console.log('error pics download', err, err.response))
  }

  async loadDay() {
    const day = this.props.state.bookmarks[df(this.props.state.rDate, 'yyyy-mm-dd')];
    this.downloadPics(df(this.props.state.rDate, 'yyyy-mm-dd'));
    if (day) this.props.setState({ dayData: day, rState: 'done', loading: false });
    else await this.downloadSingleData();
  }

  async downloadSingleData() {
    const url = `${this.hosting}/dzien/${df(this.props.state.rDate, 'yyyy-mm-dd')}/`;
    if (this.checkInRange()) {
      this.props.setState({ loading: 'Pobieram teksty...' });
      await axios({
        method: 'GET',
        url,
        headers: {
          validator: this.getCode()
        }
      })
      .then(resp => {
        console.log('single response', resp);
        if (resp.data && resp.data.length) {
          this.props.setState({ dayData: resp.data[0], rState: 'done' });
        }
        this.props.setState({ loading: '', rState: 'done' });
      })
      .catch(err => {
        console.log('download single data error', err, err.response);
        //if (this.props.state.rState === 'requestNotToday') {
        this.props.setState({
          loading: '',
          rState: 'done',
          dayData: {
            nazwa: this.days[this.props.state.rDate.getDay()],
            data: df(this.props.state.rDate, 'yyyy-mm-dd'),
            trzecie: {
              html: '<p>Nie pobrano danych. Aby przeczytać słowo na dziś, upewnij sie, że masz <u>połączenie z internetem.</u></p>',
              zrodlo: ''
            }
          }
        });
        this.log('download single data', JSON.stringify(err.response));
      });
    }
  }

  checkInRange() {
    if (
      !(this.props.state.rDate >= this.props.state.rangeDate.start &&
      this.props.state.rDate.getTime() <= this.props.state.rangeDate.stop.getTime() + 86340000)
    ) {
      this.props.setState({
        rState: 'done',
        dayData: {
          nazwa: this.days[this.props.state.rDate.getDay()],
          data: df(this.props.state.rDate, 'yyyy-mm-dd'),
          trzecie: {
            html: '<p>W bazie danych "Biblia na co dzień" nie ma jeszcze tekstów na ten dzień.</p>'
          }
        }
      });
      this.props.setState({ loading: '' })
      return false;
    }
    return true;
  }

  async updateBaseRange() {
    await axios({
      method: 'GET',
      url: `${this.hosting}/zakres/`,
      headers: {
        validator: this.getCode()
      }
    })
    .then(async resp => {
      console.log('range response', resp);
      if (resp.data) {
        this.props.setState({ rangeDate: {
          start: new Date(resp.data.min),
          stop: new Date(resp.data.max) }
        });
      }
    })
    .catch(err => {
      console.log('get base range error', err, err.response);
      this.log('updateBaseRange', JSON.stringify(err.response));
    });
  }

  async loadBookmarks() {
    const resp = localStorage.getItem('bookmarks@key')
    if (resp) {
      this.props.setState({ bookmarks: JSON.parse(resp) });
    }
  }

  setMenu() {
    getLinks(
      data => {
        /*
          [{ title, id, slug, sublinks }]
        */
        let context = {};
        data.forEach(item => {context[item.slug] = item.id});
        console.log('menu: ', data, 'context: ', context);
        this.props.setState({ menu: data, context })
      },
      () => this.props.setState({ modal: { text: 'Nie udało się pobrać strony. Spróbuj ponownie przeładować stronę.' } })
    )
  }

  log(title, body) {
    console.log('logger', title, body)
    /*axios({
      method: 'POST',
      url: 'https://db.bncd.stream/bncd/logger/',
      headers: { validator: generate() },
      data: {
        title,
        log: body
      }
    }).catch(err => console.log('logger error', err, err.response));*/
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => ({
  state: state.main,
});

const mapDispatchToProps = (dispatch) => ({
  setState: data => dispatch(setMainData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Base);

const api = 'https://db.bncd.stream/sts/api';
const mailapi = 'https://db.bncd.stream/mailing/api';
const bncdapi = 'https://db.bncd.stream/bncd/api';


const getLinks = async (onSuccess, onFail) => {
  await axios({
    method: 'GET',
    url: `${api}/get-menu/sidebar/`,
    headers: {
      validator: generate()
    },
  })
  .then(resp => {
    console.log('menu', resp)
    if (resp.data.status === 'ok') {
      console.log('menu success', resp.data.data)
      onSuccess(resp.data.data);
      /*[
        {
          name: 'string',
          id: number,
          sublinks: [
            {
              name: 'string',
              id: number
            },
            ...
          ]
        },
        ...
      ]*/
    }
  })
  .catch(err => {
    console.log(err.response, err)
    onFail()
  })
}


const getSingleArticle = (id, onSuccess, onFail) => {
  axios({
    method: 'GET',
    url: `${api}/get-single-text/${id}/`,
    headers: {
      validator: generate()
    }
  })
  .then(resp => {
    console.log('single article', resp)
    if (resp.data.status === 'ok') onSuccess(resp.data.data);
  })
  .catch(err => {
    console.log(err.response, err)
    onFail()
  })
}

const getInfo = (id, onSuccess, onFail=()=>{}) => {
  axios({
    method: 'GET',
    url: `${api}/get-info/${id}/`,
    headers: {
      validator: generate()
    }
  })
  .then(resp => {
    console.log('single info', resp)
    if (resp.data.status === 'ok') onSuccess(resp.data.data);
  })
  .catch(err => {
    console.log(err.response, err)
    onFail()
  })
}

const getMultiArticle = (id, onSuccess, onFail) => {
  axios({
    method: 'GET',
    url: `${api}/get-multi-text/${id}/`,
    headers: {
      validator: generate()
    }
  })
  .then(resp => {
    console.log('multi article', resp)
    if (resp.data.status === 'ok') onSuccess(resp.data.data);
  })
  .catch(err => {
    console.log(err.response, err)
    onFail()
  })
}

const sendSubscribeConfirmation = (mail, onSuccess, onFail) => {
  axios({
    method: 'POST',
    url: `${mailapi}/createnotif/`,
    headers: {
      validator: generate()
    },
    data: {
      mail
    }
  })
  .then(resp => {
    console.log('subscription resp', resp)
    if (resp.data.status === 'ok') onSuccess(resp.data);
    if (resp.data.status === 'fail') onFail(resp.data.error);
  })
  .catch(err => {
    console.log('subscription error', err.response, err)
    onFail();
  })
}

const sendApiKeyRequest = (web, onSuccess, onFail) => {
  axios({
    method: 'POST',
    url: `${bncdapi}/create-web-client/`,
    headers: {
      validator: generate()
    },
    data: { web }
  })
  .then(resp => {
    console.log('create web cli resp', resp)
    if (resp.data.status === 'ok') onSuccess(resp.data);
    if (resp.data.status === 'fail') onFail(resp.data.error);
  })
  .catch(err => {
    console.log('subscription error', err.response, err)
    onFail();
  })
}

const clearHtml = (txt) => {
  let rtn = txt;
  const chars = ['<p>', '</p>', '<b>', '</b>', '<i>', '</i>', '<em>', '</em>', '<u>', '</u>', '<br/>'];
  for (let i = 0; i < chars.length; i++) {
    rtn = rtn.split(chars[i]).join('');
  }
  return rtn;
};


export {
  generate,
  getLinks,
  getSingleArticle,
  getMultiArticle,
  getInfo,
  sendSubscribeConfirmation,
  sendApiKeyRequest,
  clearHtml,
}
