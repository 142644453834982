
import React, { useState, useCallback } from 'react';
import { sha256 } from 'js-sha256';
import axios from 'axios';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { setMainData } from 'actions';
import { generate } from 'components/base';
import Frame from './frame';

const log = (title, body) => {
  axios({
    method: 'POST',
    url: 'https://db.bncd.stream/bncd/logger/',
    headers: { validator: generate() },
    data: {
      title,
      log: body
    }
  }).catch(err => console.log('logger error', err, err.response));
}

const deletePayment = ref => {
  axios({
    method: 'POST',
    url: 'https://db.bncd.stream/bncd/delete-payment/',
    headers: { validator: generate() },
    data: { reference: ref }
  })
  .then(resp => {
    if (resp.data.status !== 'OK') {
      log('delete payment', JSON.stringify(resp.data));
    }
  })
  .catch(err => {
    console.log('delete err', err, err.response);
    log(
      'delete payment',
      err.response.message + '  ' + JSON.stringify(err.response.data) + '  ' + err.response.status
    );
  });
}

const requestPay = value => {
  //if (value) {
    axios({
      method: 'POST',
      url: `https://ssl.dotpay.pl/s2/login/api/v1/accounts/493077/payment_links/`,
      //url: `https://ssl.dotpay.pl/test_seller/api/v1/accounts/712394/payment_links/`,
      /*auth: {
        username: 'gitplum@gmail.com',
        password: '4dmin4dmin'
      },*/
      auth: {
        username: 'ksiegarnia@augustana.pl',
        password: 'MarcinLuter3'
      },
      data: {
        currency: 'PLN',
        description: 'Darowizna na projekt BNCD',
        amount: `${value}.00`,
        language: 'pl',
        redirection_type: 0,
        buttontext: 'Wróć do Biblii na co dzień',
        url: 'https://db.bncd.stream/bncd/webredir/',
        urlc: 'https://db.bncd.stream/bncd/register-payment/web',
        ignore_last_payment_channel: 1
      }
    })
    .then(resp => {
      console.log(resp);
      if (resp.data && resp.data.token && resp.data.payment_url) {
        deletePayment(resp.data.href);
        window.location.href = `https://ssl.dotpay.pl/t2/?chk=${sha256('vZtEmKzlywcYs1ERribKNKCYMT4H5iT3' + resp.data.token)}&pid=${resp.data.token}`
      }
    })
    .catch(err => {
      console.log('pay error', err, err.response);
      log(
        'request payment',
        err.response.message + '  ' + JSON.stringify(err.response.data) + '  ' + err.response.status
      );
    });
  //} else {
    //this.props.setModal({
    //  text: 'Wpisz poprawną kwotę w polu obok. Możliwe jest wprowadzanie wartości w zaokrągleniu do 1 zł.',
    //  content: <Icon name={'coins'} size={40} color={'white'} />
    //});
  //}
}

const mapStateToProps = (state) => ({
  state: state.main,
  bgColor: state.main.bgColor,
  fgColor: state.main.fgColor,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setState: data => dispatch(setMainData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { setState, state, fgColor, bgColor } = props

  const [ value, setValue ] = useState('');
  const [ valid, setValid ] = useState(false);
  const [ error, setError ] = useState('');

  const onValue = useCallback(eve => {
    const v = eve.target.value;
    setError('');
    setValue(v);
    const n = parseInt(v)
    if (!isNaN(n) && n) return setValid(true);
    setValid(false);
  }, [value, valid]);

  const onPay = useCallback(() => {
    if (valid) {
      setValid(false);
      requestPay(parseInt(value));
    }
  }, [value, valid])

  const onKey = useCallback(eve => {
    if (eve.key === 'Enter') {
      console.log('enter');
      onPay();
    }
  }, [onPay]);

  return (
    <Frame name='payment'>
      <h1 className={'special-dock-title' + (isMobile ? ' special-dock-title-mob' : '')} style={{ color: fgColor }}>Wspomóż cyfrowy projekt Biblia na co dzień</h1>
      <div
        className={'special-dock-content' + (isMobile ? ' special-dock-content-mob' : '')}
        style={{ borderColor: fgColor, backgroundColor: bgColor, color: fgColor }}
      >
        <p className={'special-dock-label' + (isMobile ? ' special-dock-label-mob' : '')}>
          Aby wspomóc finansowo projekt Biblia na co dzień, wpisz kwotę poniżej.
        </p>
        <p className={'special-dock-addlabel' + (isMobile ? ' special-dock-addlabel-mob' : '')}>Serdecznie dziękujemy.</p>
        <input
          className={'special-dock-input' + (isMobile ? ' special-dock-input-mob' : '')}
          placeholder="kwota w zł"
          onChange={onValue}
          onKeyDown={onKey}
        />
        <div
          style={{ transform: `scale(${valid ? 1 : 0})` }}
          className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
          onClick={onPay}
        >
          Przekaż datek
        </div>
        <p className={'special-dock-error' + (isMobile ? ' special-dock-error-mob' : '')}>{error}</p>
      </div>
      <div/>
    </Frame>
  )
})
