import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/main.css';

const checkKey = key => {
  const lst = key.split('Dj12urMW')
  const inchecker = lst.length === 2 && lst[1].length === 8;
  return (key === '') ? '' : ((key.length === 32 && inchecker) ? key : '0');
}

const isColor = s => {
  return [
    /^#[0-9A-F]{3}$/i.test(s),
    /^#[0-9A-F]{4}$/i.test(s),
    /^#[0-9A-F]{6}$/i.test(s),
    /^#[0-9A-F]{8}$/i.test(s)
  ].some(i => i)
}

const getAttributes = props => {
  const rtn = {
    withImage: false,
    withFilter: false,
    filterColor: '#273483c0',
    fontColor: undefined,
    bannerColor: undefined,
    gradient: false,
    mono: false,
    zoom: 1,
    webkey: '',
  };
  try {
    rtn['withImage'] = props['withImage'];
    rtn['withFilter'] = props['withFilter'];
    rtn['gradient'] = props['gradient'];
    rtn['mono'] = props['mono'];
    const z = Number(props['zoom']);
    rtn['zoom'] = z ? z : 1;
    const filterc = String(props['filterColor']);
    const fontc = String(props['fontColor']);
    const bannerc = String(props['bannerColor']);
    rtn['filterColor'] = isColor(filterc) ? filterc : '#273483c0';
    rtn['fontColor'] = isColor(fontc) ? fontc : undefined;
    rtn['bannerColor'] = isColor(bannerc) ? bannerc : isColor(fontc) ? fontc : undefined;
    const k = String(props['webkey'])
    rtn['webkey'] = checkKey(k);
  } catch {
    console.log('no entry')
  }
  console.log('input', rtn);
  return rtn;
}

export default props => {
  return <App {...getAttributes(props)} />
};
