
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MdLink } from 'react-icons/md';
import df from 'dateformat';
import longSources from './longSources.json';
import { isMobile } from "react-device-detect";
import { setMainData } from '../../actions';
import Clipboard from 'react-clipboard.js';

const fullSource = src => {
  let rtn = String(src)
  Object.keys(longSources).forEach(item => {
    rtn = src.includes(item) ? src.replace(item, longSources[item]) : rtn;
  });
  return rtn;
};

const mapStateToProps = (state) => ({
  zoom: state.main.zoom,
  url: state.main.url,
  date: state.main.rDate,
  fgColor: state.main.fgColor,
});
const mapDispatchToProps = (dispatch) => ({
  setModal: data => dispatch(setMainData({ modal: data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const {
    text,
    source,
    zoom,
    longterm,
    url,
    date,
    contentType,
    withLink,
    setModal,
    fgColor,
    inner
  } = props;
  const [ longSource, setLongSource ] = useState(false);
  const [ showCopy, setShowCopy ] = useState(false);

  const setValueToShowCopy = useCallback(bool => () => setShowCopy(bool), [showCopy]);
  const onClipboard = useCallback(() => setModal({text: 'skopiowano odnośnik hasła do schowka', time: 3000}), [])

  return (
    <div
      onMouseEnter={setValueToShowCopy(true)}
      onMouseLeave={setValueToShowCopy(false)}
      className={'front-sup-container' + (isMobile ? ' front-sup-container-mob' : '') + (inner ? ' front-sup-container-inner' : '')}
    >
      <div className={'frontContainer'}>
        {text.includes('<p>')
          ? <div
            id="text-transition"
            className={longterm ? 'frontTextLongterm' : 'frontText'}
            style={{ fontSize: `${(isMobile ? 20 : 30)*zoom}pt` }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          : <div><p
            id="text-transition"
            className={longterm ? 'frontTextLongterm' : 'frontText'}
            style={{ fontSize: `${(isMobile ? 20 : 30)*zoom}pt` }}
            >
              {text}
            </p></div>
        }
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {/*(withLink && showCopy) ? <Clipboard
            data-clipboard-text={`${url}/${df(date, 'yyyy-mm-dd')}/${contentType}/`}
            onSuccess={onClipboard}
            className='link-btt'
          >
            <MdLink size={60*zoom} color={fgColor} />
          </Clipboard> : null*/}
          <p
            id="text-transition"
            className={longterm ? 'frontSourceLongterm' : 'frontSource'}
            style={{ fontSize: `${(isMobile ? 16 : 24)*zoom}pt` }}
            onClick={() => setLongSource(!longSource)}
          >
            {longSource ? fullSource(source) : source}
          </p>
        </div>
      </div>
    </div>
  );
});
