
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { SingleText } from './';
import { MdLink } from 'react-icons/md';
import Clipboard from 'react-clipboard.js';
import df from 'dateformat';
import { setMainData } from '../../actions';


const mapStateToProps = (state) => ({
  zoom: state.main.zoom,
  url: state.main.url,
  date: state.main.rDate,
  fgColor: state.main.fgColor,
});
const mapDispatchToProps = (dispatch) => ({
  setModal: data => dispatch(setMainData({ modal: data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const {
    zoom,
    url,
    date,
    setModal,
    fgColor
  } = props;

  const [ showCopy, setShowCopy ] = useState(false);

  const setValueToShowCopy = useCallback(bool => () => setShowCopy(bool), [showCopy]);
  const onClipboard = useCallback(() => setModal({text: 'skopiowano link do schowka', time: 3000}), [])

  const pierwsze = {
    text: props.pierwsze.html ? props.pierwsze.html : '',
    source: props.pierwsze.zrodlo ? props.pierwsze.zrodlo : ''
  }
  const drugie = {
    text: props.drugie.html ? props.drugie.html : '',
    source: props.drugie.zrodlo ? props.drugie.zrodlo : ''
  }
  const trzecie = {
    text: props.trzecie.html ? props.trzecie.html : '',
    source: props.trzecie.zrodlo ? props.trzecie.zrodlo : ''
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}
      onMouseEnter={setValueToShowCopy(true)}
      onMouseLeave={setValueToShowCopy(false)}
    >
      <SingleText
        text={pierwsze.text}
        source={pierwsze.source}
        contentType='standard'
        withLink
      />
      <SingleText
        text={drugie.text}
        source={drugie.source}
        contentType='standard'
        withLink
      />
      <SingleText
        text={trzecie.text}
        source={trzecie.source}
        contentType='standard'
        withLink
      />
      {showCopy ? <Clipboard
        data-clipboard-text={`${url}/${df(date, 'yyyy-mm-dd')}/standard/`}
        onSuccess={onClipboard}
        className='link-btt link-btt-abs'
      >
        <MdLink size={60*zoom} color={fgColor} />
      </Clipboard> : null}
    </div>
  )
})
