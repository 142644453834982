import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { setMainData } from '../../actions';


const mapStateToProps = (state) => ({
  modal: state.main.modal,
  bgColor: state.main.bgColor,
  fgColor: state.main.fgColor,
});
const mapDispatchToProps = (dispatch) => ({
  setModal: data => dispatch(setMainData({ modal: data })),
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { setModal, bgColor, fgColor } = props;
  const { text, time, onOk } = props.modal;

  const [ opacity, setOpacity ] = useState(0);

  const close = useCallback(() => {
    if (onOk) onOk();
    setModal({ text: '', time: 0, onOk: undefined })
  }, [onOk])

  useEffect(() => {
    if (time) {
      setTimeout(() => close(), time);
    }
  }, [ time ])

  useEffect(() => {
    if (text) setTimeout(() => setOpacity(1), 500)
    else setOpacity(0);
  }, [ text ])

  const bgStyleOn = useMemo(() => ({
    width: '70%',
    backgroundColor: bgColor + 'd',
    borderColor: fgColor,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }), [ fgColor, bgColor ])

  const bgStyleOff = useMemo(() => ({
    width: 0,
    borderWidth: 0,
    borderColor: '#0000',
    backgroundColor: '#0000',
    opacity
  }), [opacity])

  const bttStyleOn = useMemo(() => ({
    width: '50%',
    borderWidth: '1px',
    borderColor: fgColor,
    backgroundColor: '#0000',
    color: fgColor,
    opacity
  }), [ fgColor, bgColor, opacity ])

  const onKey = useCallback(eve => {
    if (eve.key === 'Enter') close()
  }, [close, onOk])

  return (
    <div
      className='modal-background'
      style={text ? { backgroundColor: bgColor + 'a', zIndex: 500 } : { backgroundColor: '#0000', zIndex: -10 }}
      onKeyDown={onKey}
    >
      <div
        className='modal-container'
        style={text ? bgStyleOn : bgStyleOff}
      >
        <p className='modal-text' style={{ color: fgColor, opacity }}>
          {text}
        </p>
        <input
          type='button'
          className='modal-button'
          style={text ? bttStyleOn : bgStyleOff}
          onClick={close}
          value='ok'
        />
      </div>
    </div>
  )

});
