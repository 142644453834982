
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Day from './day';
import { Article, Articles, Info } from './sites';
import { Sidemenu } from './sidemenu';
import { Specials } from './specials';
import Baner from './baner/baner';


const checkUrl = () => {
  const path = window.location.pathname.split('/');
  if (path.length >= 4 && path[1] === 'sites') {
    switch (path[2]) {
      case 'info':
        return { info: true }
      case 'article':
        return { article: true }
      case 'articles':
        return { articles: true }
      default:
        return { day: true }
    }
  }
  return { day: true }
}


const getSlug = () => {
  const path = window.location.pathname.split('/');
  if (path.length >= 4 && path[1] === 'sites') return path[3];
  return '';
}


export default props => {

  const _default = useMemo(() => ({
    day: false,
    article: false,
    articles: false,
    info: false,
  }))

  const [ viewer, setViewer ] = useState(_default)
  const [ slug, setSlug ] = useState('')
  const [ offset, setOffset ] = useState(100)

  const onChangeOffset = useCallback(off => setOffset(off), [ offset ])

  useEffect(() => {
    setViewer({ ..._default, ...checkUrl() });
    setSlug(getSlug())
  }, [])

  return (
    <div>
      <Baner onChangeOffset={onChangeOffset} />
      {viewer.day ? <Day offset={offset}/> : null}
      {viewer.article ? <Article slug={slug} offset={offset} /> : null}
      {viewer.articles ? <Articles slug={slug} offset={offset} /> : null}
      {viewer.info ? <Info slug={slug} offset={offset} /> : null}
      <Sidemenu offset={offset} />
      <Specials />
    </div>
  )
}
