
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MdZoomIn } from 'react-icons/md';
import { setMainData } from '../../actions';
import { saveUtil } from './control';
import { isMobile } from "react-device-detect";


const mapStateToProps = (state) => ({
  color: state.main.fgColor,
  bgColor: state.main.bgColor,
  zoom: state.main.zoom,
});
const mapDispatchToProps = (dispatch) => ({
  setZoom: data => dispatch(setMainData({ zoom: data })),
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { color, bgColor, setZoom, zoom } = props;

  const [ isScroll, setIsScroll ] = useState(false);
  const [ scrollShow, setScrollShow ] = useState(false);

  const toggleScroll = useCallback(() => {
    const present = Boolean(isScroll);
    setIsScroll(!present)
    if (present) setScrollShow(!present);
    else setTimeout(() => setScrollShow(!present), 200)
  }, [isScroll]);

  const linearizeZoom = useCallback( eve => setZoom(eve.target.value), [zoom]);
  const onContext = useCallback(eve => {
    eve.preventDefault();
    return false;
  }, [])

  const saveZoom = useCallback(() => {
    saveUtil('zoom', zoom)
  }, [zoom, saveUtil])

  const expStyle = isScroll ? isMobile ? {
    width: '120px',
    height: '40px',
    display: 'flex',
    paddingLeft: '8px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    borderRadius: '5px',
    lineHeight: '45px'
  } : {
    width: '180px',
    height: '50px',
    display: 'flex',
    paddingLeft: '10px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    borderRadius: '5px',
    lineHeight: '65px'
  } : {}

  return (
    <div className={(isMobile || isScroll) ? "util-item-mob" : "util-item"} style={{ borderColor: color, backgroundColor: bgColor + 'a', ...expStyle }}>
      <div onClick={toggleScroll} style={{ alignItems: 'center' }}>
        <MdZoomIn color={color} size={isMobile ? 20 : 30} style={{ margin: isScroll ? '10px' : 0 }} />
      </div>
      {scrollShow ? <input onContextMenu={onContext} type='range' onMouseUp={saveZoom} onTouchEnd={saveZoom} className="slider" value={zoom} onChange={linearizeZoom} min={0.5} max={isMobile ? 1.25 : 1.7} step={0.01} /> : null}
    </div>
  );
});
