
import React, { useState, useCallback, useEffect } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Styling from './styles/styles';
import rootReducer from './reducers';
import Base from './components/base';
import Main from './components/main';
import Modal from './components/common/modal';

const store = createStore(rootReducer);


//const akey = '73s7818L11N4C0Dz13N'

//let allow = localStorage.getItem('allow') || '';

export default props => {

  /*const [inp, setInp] = useState('')
  const onInput = useCallback(eve => {
    const v = eve.target.value;
    setInp(v);
    localStorage.setItem('allow', v);
  }, [inp])

  useEffect(() => setInp(String(allow)), [])*/

  return (
    <Provider store={store}>
      <div className="App">
        {/*(inp === akey) ? <>*/}
          <Base />
          <Main />
          <Modal />
          <Styling />
        {/*</> : <input onChange={onInput} placeholder='wpisz kod' />*/}
      </div>
    </Provider>
  );
}
