
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { MdMailOutline, MdCode, MdPayment } from 'react-icons/md';
import { Subscribe, Developers, Payment } from './';
import { setMainData, setSpecial } from '../../actions';


const mapStateToProps = (state) => ({
  fgColor: state.main.fgColor,
  bgColor: state.main.bgColor,
  globalSpecialVisible: state.main.globalSpecialVisible,
});
const mapDispatchToProps = (dispatch) => ({
  setState: data => dispatch(setMainData(data)),
});


const mapStateToProps_btt = (state) => ({
  fgColor: state.main.fgColor,
  bgColor: state.main.bgColor
});
const mapDispatchToProps_btt = (dispatch) => ({
  setSpecial: (name, data) => dispatch(setSpecial(name, data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { fgColor, bgColor, setState, globalSpecialVisible, specials } = props;

  return !isMobile ? (
    <div
      className={(isMobile ? 'special-container-mob ' : '') + 'special-container'}
      style={{ left: globalSpecialVisible ? 0 : -100 }}
    >
      <SpecButton Component={Subscribe} Icon={MdMailOutline} text={'Subskrybcja na e-mail'} name={'subscribe'} />
      <SpecButton Component={Developers} Icon={MdCode} text={'Wstaw BNCD na swoją stronę'} name={'developers'} />
      <SpecButton Component={Payment} Icon={MdPayment} text={'Wesprzyj projekt'} name={'payment'} />
    </div>
  ) : (<>
    <Subscribe />
    <Developers />
    <Payment />
  </>)
})

const SpecButton = connect(mapStateToProps_btt, mapDispatchToProps_btt)(props => {
  const { Component, Icon, text, name, fgColor, bgColor, setSpecial } = props

  const [ expand, setExpand ] = useState(false);

  const onClick = useCallback(() => setSpecial(name, true), [setSpecial])
  const onHover = useCallback(() => setExpand(true), [expand])
  const onLeave = useCallback(() => setExpand(false), [expand])

  return (
    <>
    <Component />
    <div
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      className={(isMobile ? 'special-button-mob ' : '') + 'special-button'}
      style={{ borderColor: fgColor, backgroundColor: bgColor + 'a' }}
    >
      <Icon size={35} color={fgColor} />
      {expand ? <p style={{ color: fgColor }}>
        {text}
      </p> : null}
    </div>
    </>
  )
})
