import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  ViberShareButton,
  PocketShareButton,
  InstapaperShareButton,

  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  PocketIcon,
  InstapaperIcon,
} from 'react-share';
import { isMobile } from "react-device-detect";


export default props => {
  const { url, title, text, titleText, image, onClose } = props;
  const onShareClose = () => onClose();

  return (
    <div className="sharing-container" style={{ width: isMobile ? '40px' : '50px' }}>
      <div className="sharing-network">
        <FacebookShareButton
          url={url}
          quote={titleText}
          hashtag={'biblianacodzien'}
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <FacebookIcon
            size={40}
            round
          />
        </FacebookShareButton>
      </div>

      <div className="sharing-network">
        <TwitterShareButton
          title={text}
          url={url}
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <TwitterIcon
            size={40}
            round
          />
        </TwitterShareButton>
      </div>

      <div className="sharing-network">
        <WhatsappShareButton
          url={url}
          title={text+titleText}
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <WhatsappIcon
            size={40}
            round
          />
        </WhatsappShareButton>
      </div>

      <div className="sharing-network">
        <TelegramShareButton
          url={url}
          title={text+titleText}
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <TelegramIcon
            size={40}
            round
          />
        </TelegramShareButton>
      </div>

      <div className="sharing-network">
        <RedditShareButton
          url={url}
          title={text+titleText}
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <RedditIcon
            size={40}
            round
          />
        </RedditShareButton>
      </div>

      <div className="sharing-network">
        <ViberShareButton
          url={url}
          title={text+titleText}
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <ViberIcon
            size={40}
            round
          />
        </ViberShareButton>
      </div>

      <div className="sharing-network">
        <PocketShareButton
          url={url}
          title={text+titleText}
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <PocketIcon
            size={40}
            round
          />
        </PocketShareButton>
      </div>

      <div className="sharing-network">
        <EmailShareButton
          url={url}
          subject={title}
          body={text+titleText}
          separator='\n\n'
          className="sharing-button"
          windowWidth={isMobile ? '80%' : '500px'}
          onShareWindowClose={onShareClose}
        >
          <EmailIcon
            size={40}
            round
          />
        </EmailShareButton>
      </div>
    </div>
  )
}
