
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MdLink } from 'react-icons/md';
import df from 'dateformat';
import { SingleText } from './';
import { setMainData } from '../../actions';
import Clipboard from 'react-clipboard.js';

const mapStateToProps = (state) => ({
  url: state.main.url,
  date: state.main.rDate,
  zoom: state.main.zoom,
  fgColor: state.main.fgColor
});
const mapDispatchToProps = (dispatch) => ({
  setModal: data => dispatch(setMainData({ modal: data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {

  const { setModal, url, date, zoom, fgColor } = props;

  const [ showCopy, setShowCopy ] = useState(false);
  const setValueToShowCopy = useCallback(bool => () => setShowCopy(bool), [showCopy]);
  const onClipboard = useCallback(() => setModal({text: 'skopiowano odnośnik hasła do schowka', time: 3000}), [])


  const data = {
    text: props.data.haslo.text ? props.data.haslo.text : '',
    source: props.data.haslo.zrodlo ? props.data.haslo.zrodlo : ''
  }

  return (
    <div className='longterm-container' style={{ minHeight: window.innerHeight - 80 }}>
      <div
        onMouseEnter={setValueToShowCopy(true)}
        onMouseLeave={setValueToShowCopy(false)}
        className='longterm-title-container'
      >
        {(showCopy) ? <Clipboard
          data-clipboard-text={`${url}/${df(date, 'yyyy-mm-dd')}/year/`}
          onSuccess={onClipboard}
          className='link-btt'
        >
          <MdLink size={60*zoom} color={fgColor} />
        </Clipboard> : null}
        <h2 onClick={() => props.onTitleClick('year')} className="longterm-title">Hasło roku<br/>{props.data.numer ? props.data.numer : ''}</h2>
      </div>
      <SingleText text={data.text} source={data.source} longterm />
    </div>
  )
})
