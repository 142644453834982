
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { setMainData } from 'actions';
import { sendSubscribeConfirmation } from 'components/base';
import Frame from './frame';

const mapStateToProps = (state) => ({
  state: state.main,
  bgColor: state.main.bgColor,
  fgColor: state.main.fgColor,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setState: data => dispatch(setMainData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { setState, state, fgColor, bgColor } = props

  const [ mail, setMail ] = useState('');
  const [ valid, setValid ] = useState(false);
  const [ error, setError ] = useState('');

  const onMail = useCallback(eve => {
    const v = eve.target.value;
    setError('');
    setMail(v);
    const [ p0, p1 ] = v.split('@');
    if (p0 && p1) {
      if (p1.length > 3) {
        const [ b0, b1 ] = p1.split('.')
        if (b0 && b1) return setValid(true);
      }
    }
    setValid(false);
  }, [mail, valid]);

  const onSend = useCallback(() => {
    if (valid) {
      setValid(false);
      sendSubscribeConfirmation(
        mail,
        () => {
          setState({
            modal: {
              text: 'Dziękujemy, za wpisanie się na listę subskrybentów. Proszę sprawdzić skrzynkę odbiorczą i potwierdzić subskrybcję.'
            },
            specials: { payment: false, developers: false, subscribe: false }
          })
          setValid(true);
          setError('');
        },
        message => {
          switch (message) {
            case 'mail allready exist':
              setError('Ten adres e-mail już znajduje się na liście subskrybentów.')
              break;
            case 'bad request':
              setError('Wystąpił problem logiczny z połączeniem. Proszę spróbować jeszcze raz.')
              break;
            case 'bad validation':
              setError('Wystąpił problem logiczny z połączeniem. Proszę spróbować jeszcze raz.')
              break;
            case 'sending error':
              setError('Wystąpił problem po stronie klienta poczty. Proszę skontaktować się z administratorem serwisu.')
              break;
            default:
              setError('Wystąpił niespodziewany problem. Proszę skontaktować się z administratorem serwisu.')
              break;
          }
          setValid(true);
        }
      );
    }
  }, [mail, valid, setState, setValid, error])

  const onKey = useCallback(eve => {
    if (eve.key === 'Enter') {
      console.log('enter');
      onSend();
    }
  }, [onSend]);

  return (
    <Frame name='subscribe'>
      <h1 className={'special-dock-title' + (isMobile ? ' special-dock-title-mob' : '')} style={{ color: fgColor }}>Subskrybuj Biblię na co dzień!</h1>
      <div
        className={'special-dock-content' + (isMobile ? ' special-dock-content-mob' : '')}
        style={{ borderColor: fgColor, backgroundColor: bgColor, color: fgColor }}
      >
        <p
          className={'special-dock-label' + (isMobile ? ' special-dock-label-mob' : '')}
        >Wpisz adres e-mail</p>
        <p
          className={'special-dock-addlabel' + (isMobile ? ' special-dock-addlabel-mob' : '')}
        >Na podany adres wyślemy e-mail aktywacyjny. Przechowywanie i przetwarzanie danych osobowych odbywa się w oparciu o <a href="https://biblianacodzien.pl/sites/info/privacy_policy/">politykę prywatnosci</a>.</p>
        <input
          className={'special-dock-input' + (isMobile ? ' special-dock-input-mob' : '')}
          placeholder="jan.kowalski@mail.com"
          onChange={onMail}
          onKeyDown={onKey}
        />
        <div
          style={{ transform: `scale(${valid ? 1 : 0})` }}
          className={'special-dock-button' + (isMobile ? ' special-dock-button-mob' : '')}
          onClick={onSend}
        >
          Subskrybuj
        </div>
        <p className={'special-dock-error' + (isMobile ? ' special-dock-error' : '')}>{error}</p>
      </div>
      <div/>
    </Frame>
  )
})
