
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { MdMonochromePhotos } from 'react-icons/md'
import { setMainData } from '../../actions'
import { saveUtil } from './control';
import { isMobile } from "react-device-detect";


const mapStateToProps = (state) => ({
  color: state.main.fgColor,
  bgColor: state.main.bgColor,
  isBgImg: state.main.isBgImg,
});
const mapDispatchToProps = (dispatch) => ({
  setBgImg: data => dispatch(setMainData({ isBgImg: data })),
});


export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { color, bgColor, setBgImg, isBgImg } = props;
  const toggleBgImg = useCallback(() => {
    saveUtil('bgImg', !Boolean(isBgImg));
    setBgImg(!Boolean(isBgImg));
  }, [isBgImg])

  return (
    <div className={isMobile ? "util-item-mob" : "util-item"} style={{ borderColor: color, backgroundColor: bgColor + 'a' }} onClick={toggleBgImg}>
      <MdMonochromePhotos color={isBgImg ? color + '4' : color} size={isMobile ? 20 : 30} />
    </div>
  );
});
