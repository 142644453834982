
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MdShare } from 'react-icons/md'
import df from 'dateformat';
import { setMainData } from '../../actions'
import { saveUtil, loadUtilities } from './control';
import { Sharing } from '../common';
import { isMobile } from "react-device-detect";


const mapStateToProps = (state) => ({
  color: state.main.fgColor,
  bgColor: state.main.bgColor,
  data: state.main.dayData,
  date: state.main.rDate,
  url: state.main.url,
});


const clearHtml = (txt) => {
  let rtn = txt;
  const chars = ['<p>', '</p>', '<b>', '</b>', '<i>', '</i>', '<u>', '</u>', '<br/>'];
  for (let i = 0; i < chars.length; i++) {
    rtn = rtn.split(chars[i]).join('');
  }
  return rtn;
};

const getTitle = (data, date) => {
  return `${data.nazwa} ${data.swieta.map(item => (item.nazwa + ' '))}- ${df(date, 'd.mm.yyyy')}`
}

const getText = (data) => {
  return `${clearHtml(data.pierwsze.html)}
  ${data.pierwsze.zrodlo}

${clearHtml(data.drugie.html)}
  ${data.drugie.zrodlo}

${clearHtml(data.trzecie.html)}
  ${data.trzecie.zrodlo}

`
}

const getTitleText = (data) => {
  return 'Biblia na co dzień'
}


export default connect(mapStateToProps)(props => {
  const { color, bgColor, pic, data, date, url } = props;

  const [ expand, setExpand ] = useState(false);

  const toggleExpand = useCallback(() => {
    setExpand(!Boolean(expand));
  }, [expand]);

  const expStyle = expand ? isMobile ? { width: '40px', height: '40px', lineHeight: '45px' }  : { width: '50px', height: '50px', lineHeight: '65px' } : {}

  return (
    <div className={(isMobile || expand) ? "util-item-mob" : "util-item"} style={{ borderColor: color, backgroundColor: bgColor + 'a', ...expStyle }}>
      <div onClick={toggleExpand}>
        <MdShare color={color} size={isMobile ? 20 : 30} />
      </div>
      {expand ? <Sharing
        title={getTitle(data, date)}
        text={getText(data)}
        titleText={getTitleText(data)}
        url={`${url}/${df(date, 'yyyy-mm-dd')}/dzien/`}
        onClose={toggleExpand}
        image={pic}
      /> : null}
    </div>
  );
});
