import React, { useState, useEffect, useMemo } from 'react';
import monoBNCD from './bncd_mono';

const colorHexToNakedDec = col => {
    return (col.length === 4 || col.length === 5) ? hexToRgb3(col) : hexToRgb6(col)
  }

const hexToRgb6 = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '0, 0, 0'
}

const hexToRgb3 = hex => {
  const c = hex.split('').filter((i, idx) => (i !== '#' && idx < 4)).map(item => ((parseInt(item, 16) + 1) * 16 - 1))
  console.log('color', hex, c)
  return c.join(', ')
}

export default props => {
  const { withImage, withFilter, filterColor, bannerColor, fontColor, webkey, gradient, zoom, mono } = props; //webkey: '' || '0' || '32key'

  console.log('props', props)

  const [state, setState] = useState({
    status: false,
    image: '',
    date: undefined,
    day: undefined,
    is_name: false,
    name: '',
    is_first: false,
    first: '',
    first_s: '',
    is_second: false,
    second: '',
    second_s: '',
    is_third: false,
    third: '',
    third_s: '',
  })

  useEffect(() => {
    console.log('key', webkey)
    fetch('https://db.bncd.stream/bncd/api/open-node/', {
      method: 'POST',
      body: JSON.stringify({ key: webkey, embedded: window.location.href})
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
      if (data.status === 'ok') {
        setState({ ...data, status: true });
      } else setState({ ...state, status: false});
    })
    .catch(err => {
      console.log('download data error', err);
      setState({ ...state, status: false});
    });
  }, [webkey])

  const imgbg = useMemo(() => {
    const bgimg = []
    if (withFilter) {
      bgimg.push(`linear-gradient(rgba(${colorHexToNakedDec(filterColor)}, ${gradient ? .6 : (withImage ? .75 : 1)}) 0%, rgba(${colorHexToNakedDec(filterColor)}, ${gradient ? .8 : (withImage ? .75 : 1)}) 75%, rgba(${colorHexToNakedDec(filterColor)}, ${(withImage && !gradient) ? .75 : 1}) 100%)`)
    }
    if (withImage) {
      bgimg.push(`url(${state.image})`)
    }
    const rtn = bgimg.length ? `${bgimg[0]}${bgimg.length === 2 ? (', ' + bgimg[1]) : ''}` : '';
    return rtn ? { backgroundImage: rtn } : {}
  },[filterColor, withFilter, withImage, gradient, state.image]);

  return (!webkey) ? (<h2>Brak klucza api, zapraszamy na stronę <a href="https://biblianacodzien.pl">Biblia na co dzień</a> aby dowiedzieć się więcej na temat klucza api</h2>) : (webkey === '0') ? (<h2>Błędny klucz api</h2>) : state.status ? (
    <div className="bncd-webwidget-container">
      <img className="bncd-webwidget-background-image" src={state.image} style={{ display: 'none'}} />
      <div className="bncd-webwidget-background-image" style={{ ...imgbg }}>
        <a href="https://biblianacodzien.pl" className="bncd-webwidget-banner-container">
          {mono ? <div width="100%" className="bncd-webwidget-banner" dangerouslySetInnerHTML={{ __html: monoBNCD(props={color: bannerColor}) }}/> : <img src="https://bncd-storage.s3.eu-north-1.amazonaws.com/media/logo/bncd.svg" width="100%" className="bncd-webwidget-banner" />}
        </a>
        <div className="bncd-webwidget-content-container" style={{ color: fontColor, padding: (withImage || withFilter) ? '3%' : 0 }}>
          {(state.date && state.day) ? <p style={{ fontSize: `${18*zoom}pt`}} className="bncd-webwidget-date">{state.date} <b>{state.day}</b></p> : null}
          {state.is_name ? <p style={{ fontSize: `${13*zoom}pt`}} className="bncd-webwidget-text-red">{state.name}</p> : null}
          {state.is_first ? <div style={{ fontSize: `${16*zoom}pt`}} className="bncd-webwidget-text" dangerouslySetInnerHTML={{ __html: state.first }} /> : null}
          {state.first_s ? <p style={{ fontSize: `${13*zoom}pt`}} className="bncd-webwidget-text-source">{state.first_s}</p> : null}
          {state.is_second ? <div style={{ fontSize: `${16*zoom}pt`}} className="bncd-webwidget-text" dangerouslySetInnerHTML={{ __html: state.second }} /> : null}
          {state.second_s ? <p style={{ fontSize: `${13*zoom}pt`}} className="bncd-webwidget-text-source">{state.second_s}</p> : null}
          {state.is_third ? <div style={{ fontSize: `${16*zoom}pt`}} className="bncd-webwidget-text" dangerouslySetInnerHTML={{ __html: state.third }} /> : null}
          {state.third_s ? <p style={{ fontSize: `${13*zoom}pt`}} className="bncd-webwidget-text-source">{state.third_s}</p> : null}
        </div>
      </div>
    </div>
  ) : (
    <a href="https://biblianacodzien.pl">
      <img src="https://bncd-storage.s3.eu-north-1.amazonaws.com/media/logo/bncd.svg" width="100%" />
    </a>
  )
}
